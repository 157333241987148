import React, { useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { Dropdown } from 'primereact/dropdown'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import Tippy from '@tippyjs/react'

import { HiOutlineRefresh } from 'react-icons/hi'
import { BsShuffle, BsXLg, BsDice6 } from 'react-icons/bs'

import './style.scss'
import { editingPlaylistDataState } from '../../utilities/store'
import { postIt, putIt } from '../../utilities/network'
import { useAuth } from '../../hooks/useAuth'
import { MultiSelect } from 'primereact/multiselect'

const PlaylistCard = ({ playlist, primaries, manualSyncPlaylist, allTags, cardSize }) => {
	const [parentPlaylist, setParentPlaylist] = useState(playlist?.parent || '')
	const [primary, setPrimary] = useState(playlist?.primary || false)
	const [randomizeRemainder, setRandomizeRemainder] = useState(playlist?.randomize?.randomize_remaining_tracks)
	const [randomizeFirst, setRandomizeFirst] = useState(playlist?.randomize?.randomize_first_tracks)
	const [tags, setTags] = useState(playlist?.tags || [])
	const setEditingPlaylist = useSetRecoilState(editingPlaylistDataState)

	const { user } = useAuth()

	const cardWidth = 125 * (cardSize * 0.3 + 1)

	const stateOptions = [{ label: 'No Primary', value: '' }, ...primaries.map(val => ({ label: val.name, value: val.id }))]

	const switchPrimary = async val => {
		setPrimary(val)
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'primary', value: val })
		console.log(res)
	}

	const switchParent = async val => {
		setParentPlaylist(val)
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'parent', value: val })
		console.log(res)
	}

	const updateTags = async val => {
		setTags(val)
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'tags', value: val })
		console.log(res)
	}

	const getToggleColor = val => {
		if (val === undefined || val === null) {
			return 'inherit'
		}
		return val ? 'green' : 'red'
	}

	const threeToggleRandomRest = async () => {
		let newVal
		if (randomizeRemainder === null || randomizeRemainder === undefined) newVal = true
		if (randomizeRemainder === true) newVal = false
		if (randomizeRemainder === false) newVal = null
		setRandomizeRemainder(newVal)
		const res = await putIt('/playlist', { id: playlist.id, field: 'randomize.randomize_remaining_tracks', value: newVal })
		console.log(res)
	}

	const threeToggleRandomFirst = async () => {
		let newVal
		if (randomizeFirst === null || randomizeFirst === undefined) newVal = true
		if (randomizeFirst === true) newVal = false
		if (randomizeFirst === false) newVal = null
		setRandomizeFirst(newVal)
		const res = await putIt('/playlist', { id: playlist.id, field: 'randomize.randomize_first_tracks', value: newVal })
		console.log(res)
	}

	const formatDate = (stringDate) => {
		if (!stringDate) {
			return
		}
		const options = { year: 'numeric', month: 'short', day: 'numeric' }
		const theDate = new Date(stringDate)
		return theDate.toLocaleDateString('en-US', options)
	}

	return (
		<div className={'playlist-card-container'} style={{ marginRight: '0.5%', marginBottom: 5, width: cardWidth }}>
			<Card className={`shadow-2 playlist-card ${playlist.owner_id !== user.spotify_id ? 'tp-card-background' : ''}`}>
				<div style={{ display: 'flex', flexDirection: 'col' }}>
					<div style={{ flex: 1, maxWidth: cardWidth - 12 }}>
						<Link to={`/app/edit-playlist?id=${playlist.id}`} onClick={() => {
							setEditingPlaylist(playlist)
							localStorage.setItem('editingPlaylistData', JSON.stringify(playlist))
						}}>
							<div style={{ width: '100%', minWidth: cardWidth - 12, height: 0, paddingBottom: '100%', overflow: 'hidden', position: 'relative' }}>
								<img style={{ position: 'absolute', objectFit: 'cover', objectPosition: 'center center', width: '100%', height: '100%' }} src={playlist?.imgURL ? playlist.imgURL : 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/f5a34e108782021.5fc5820ec88bf.png'} />
							</div>
						</Link>
						{
							!playlist?.primary &&
							<div>
								{
									playlist.parent &&
									<div style={{ height: 10, marginTop: 6, display: 'flex', gap: 5 }}>
										<span style={{ marginTop: -3 }}>{playlist?.leading_tracks ? playlist.leading_tracks.length : 0}</span>
										<BsDice6 className="click-icon" style={{ color: getToggleColor(randomizeFirst) }}
											onClick={() => threeToggleRandomFirst()} />
										<BsShuffle className="click-icon" style={{ color: getToggleColor(randomizeRemainder) }}
											onClick={() => threeToggleRandomRest()} />

										{/* <HiOutlineRefresh /> */}
										<div style={{ flex: 1 }}>
											<BsXLg style={{ float: 'right' }} className="cancel-icon" onClick={() => switchParent('')} />
										</div>
									</div>
								}
							</div>
						}

						{/* Last synced date */}
						<Tippy content={playlist.name}><h4 className='playlist-card-name' style={{ width: cardWidth - 12, height: 40, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
							{playlist.name}
						</h4></Tippy>
						<div style={{ display: 'flex', marginTop: 10 }}>
							<div style={{ marginTop: -3 }}>
								<HiOutlineRefresh style={{ marginRight: 5 }} />
							</div>
							<span style={{ marginTop: -5 }}>{formatDate(playlist?.last_synced)}</span>
						</div>
						{/* Sync errors */}
						{playlist?.sync_error &&
							<div style={{ display: 'flex', marginTop: 5 }}>
								<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<Button className="p-button-danger " label="Manual Sync Required" onClick={() => manualSyncPlaylist(playlist.id)}></Button>
								</div>
							</div>
						}
						{
							(playlist?.primary || !playlist?.parent) &&
							<div style={{ display: 'flex' }}>
								<div style={{ flex: 1 }}>
									<p style={{ marginTop: 15, marginBottom: 15 }}>Parent</p>
								</div>
								<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
									<InputSwitch checked={primary} onChange={(e) => switchPrimary(e.value)} disabled={playlist.owner_id !== user.spotify_id} />
								</div>
							</div>
						}
						<p>{tags.length} tag{tags.length === 1 ? '' : 's'}</p>
						<MultiSelect style={{ width: '100%' }} display="chip" removeIcon={''} value={tags || []} options={allTags.map(tag => tag.tag) || []}
							onChange={async (e) => {
								await updateTags(e.value)
							}
							} />
						{
							// Add dropdown of primary playlists to any non-primary playlist
							(!playlist.parent || primaries.findIndex(primary => primary.id === playlist.parent) === -1) &&
							<Dropdown style={{ width: '100%', marginTop: 14 }} value={parentPlaylist} options={stateOptions} onChange={(e) => switchParent(e.value)} placeholder="No parent playlist" />
						}
					</div>
				</div>
			</Card>

		</div >
	)
}

export default PlaylistCard
