import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { AutoComplete } from 'primereact/autocomplete'
import { InputSwitch } from 'primereact/inputswitch'

import { dateString } from '../utilities/utils'
import { fetcher, postIt } from '../utilities/network'

import useSWR from 'swr'

const LinkManager = (props) => {
	const [incomingData, setIncomingData] = useState(null)
	const [switches, setSwitches] = useState(Array(24).fill(true))
	const [filteredPlaylists, setFilteredPlaylists] = useState([])
	const [selectedPlaylists, setSelectedPlaylists] = useState([])
	const [playlistsInfo, setPlaylistsInfo] = useState([])
	const toast = useRef(null)

	const { data, error } = useSWR('/appInfo?name=toneden-redirects', fetcher)

	useEffect(() => {
		async function getPlaylistNames () {
			// Restructure the search data so it works with our table
			const playlistInfoRes = await fetcher('/getAllPlaylistNamesAndIDs')
			const playlistInfoData = playlistInfoRes.data.map(obj => ({ ...obj, display_name: `${obj.name} - ${obj.owner_name}` }))
			setPlaylistsInfo(playlistInfoData)
		}
		getPlaylistNames()
	}, [])

	useEffect(() => {
		// Restructure the search data so it works with our table
		if (!data) {
			return
		}
		if (incomingData === null) {
			const theData = data.data.data
			const newSelectedPlaylists = [...selectedPlaylists]
			const newSwitches = [...switches]
			for (let i = 0; i < theData.length; i++) {
				const row = theData[i]
				document.getElementById(`adname-${i}`).value = row.adname
				document.getElementById(`notes-${i}`).value = row.notes
				document.getElementById(`spotify-id-${i}`).value = row.spotifyID
				newSwitches[i] = row.on || false
				newSelectedPlaylists[i] = {
					name: row.spotifyName || '',
					id: row.spotifyID || '',
					display_name: row.spotifyName || ''
				}
			}
			setSelectedPlaylists(newSelectedPlaylists)
			setIncomingData(theData)
			setSwitches(newSwitches)
		}
	}, [data])

	if (error) {
		console.log('Uh captain, we have a problemo')
	}

	async function saveChangesToDB () {
		// Get all the data
		const dataToSave = []
		const eventsToLog = []
		for (let i = 0; i < 24; i++) {
			dataToSave.push(
				{
					adname: document.getElementById(`adname-${i}`).value,
					spotifyName: document.querySelector(`#spotify-name-${i} input`).value,
					spotifyID: document.getElementById(`spotify-id-${i}`).value,
					notes: document.getElementById(`notes-${i}`).value,
					on: switches[i]
				}
			)
			// If the id is filled and either different from before OR the switch was turned on, log start event
			if ((dataToSave[i].spotifyID !== '') && ((dataToSave[i].spotifyID !== incomingData[i].spotifyID) || ((incomingData[i].on !== false) && (dataToSave[i].on === true)))) {
				eventsToLog.push({
					id: dataToSave[i].spotifyID,
					data: {
						date: dateString(new Date()),
						event: 'AD-S',
						comment: `Running ads on ${dataToSave[i].adname} campaign via ToneDen`
					}
				})
				// If that spot wasn't blank before, log an end event
				if (incomingData[i].spotifyID !== '') {
					eventsToLog.push({
						id: incomingData[i].spotifyID,
						data: {
							date: dateString(new Date()),
							event: 'AD-E',
							comment: `Stopped ads on ${dataToSave[i].adname} campaign via ToneDen.`
						}
					})
				}
			}
			// If it's blank but wasn't before, OR switch is now off, send End event
			if (((incomingData[i].spotifyID !== '') && (dataToSave[i].spotifyID === '')) || ((incomingData[i].on !== true) && (dataToSave[i].on === false))) {
				eventsToLog.push({
					id: incomingData[i].spotifyID,
					data: {
						date: dateString(new Date()),
						event: 'AD-E',
						comment: `Stopped ads on ${dataToSave[i].adname} campaign via ToneDen.`
					}
				})
			}
		}
		const savingRes = await postIt('/appInfo', {
			name: 'toneden-redirects',
			data: dataToSave
		})
		if (savingRes.status === 200) {
			toast.current.show({ severity: 'success', summary: 'Saved changes to db', detail: 'Nice.', life: 3000 })
		} else {
			toast.current.show({ severity: 'error', summary: 'Issue saving changes to db', detail: 'Oh no.', life: 3000 })
		}

		const eventsRes = await postIt('/logEvents', { events: eventsToLog })
		if (eventsRes.status === 200) {
			toast.current.show({ severity: 'success', summary: 'Success', detail: 'Logged events to db.', life: 3000 })
		} else {
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Issue logging events to db', life: 3000 })
		}
		setIncomingData(dataToSave)
	}

	const searchPlaylists = (event) => {
		const query = event.query
		const _filteredPlaylists = playlistsInfo.filter((obj) => obj.display_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
		console.log(_filteredPlaylists)
		setFilteredPlaylists(_filteredPlaylists)
	}

	return <React.Fragment>
		<div className="p-card p-fluid" style={{ marginTop: 10, padding: 20, borderRadius: 10 }}>
			<div style={{ display: 'flex' }}>
				<h2 style={{ marginTop: 0, flex: 1 }}>Link Manager</h2>
				<Button
					type="button"
					label={'Save Changes'}
					icon={<i className="ri-save-line"></i>}
					style={{ marginLeft: 10, width: 160 }}
					onClick={() => saveChangesToDB()}></Button>
			</div>

			<p>Each link is <a style={{ color: 'blue' }} href="https://hackertyper.com/">https://api.littlesymphonyrecords.com/playlist/ADNAME/DSP</a> (ex. https://api.littlesymphonyrecords.com/playlist/babysleep/spotify)</p>
			<p>Click the copy button to get the custom link.</p>
			<br /><br />
			<div>
				{[...Array(24)].map((_, i) =>
					<div style={{ gap: 10, marginBottom: 30 }} key={i}>
						<div style={{ display: 'flex' }}>

							<h3 style={{ margin: 10, marginTop: 0, display: 'inline-block' }}>Campaign #{i + 1}</h3>
							<InputSwitch checked={switches[i]} onChange={(e) => {
								const newSwitches = [...switches]
								newSwitches[i] = e.value
								setSwitches(newSwitches)
							}} />
						</div>

						<div style={{ width: '100%', display: 'flex', gap: 10, marginBottom: 10 }}>
							<div style={{ flex: 1 }}>
								<span className="p-input-icon-left p-float-label">
									<i className="ri-focus-2-line"></i>
									<InputText id={`adname-${i}`} placeholder='Ad Name (all lowercase, no spaces)' />
								</span>
							</div>
							<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 10 }}>
								<div style={{ display: 'flex' }}>
									<div style={{ flex: 1, display: 'flex' }}>
										<span className="p-input-icon-left p-float-label" style={{ flex: 1 }}>
											<i className="ri-play-list-line"></i>

											<AutoComplete forceSelection
												value={selectedPlaylists[i]} suggestions={filteredPlaylists}
												completeMethod={searchPlaylists} field="display_name"
												placeholder='Spotify Playlist Name'
												onChange={(e) => {
													if (e.value === null) {
														return
													}
													if (typeof e.value === 'object') {
														const newSelectedPlaylists = [...selectedPlaylists]
														newSelectedPlaylists[i] = e.value.display_name
														document.getElementById(`spotify-id-${i}`).value = e.value.id
														setSelectedPlaylists(newSelectedPlaylists)
													} else {
														const newSelectedPlaylists = [...selectedPlaylists]
														newSelectedPlaylists[i] = { display_name: e.value, id: '' }
														document.getElementById(`spotify-id-${i}`).value = ''
														setSelectedPlaylists(newSelectedPlaylists)
													}
												}}
												id={`spotify-name-${i}`} />
										</span>
										<Button
											type="button"
											className="p-button-outlined"
											icon={<i className="ri-external-link-line"></i>}
											style={{ marginLeft: 10, width: 45 }}
											onClick={() => {
												window.open(`https://open.spotify.com/playlist/${document.getElementById(`spotify-id-${i}`).value}`, '_blank').focus()
											}}></Button>
									</div>
								</div>
							</div>
						</div>
						<div style={{ width: '100%', display: 'flex', gap: 10 }}>
							<div style={{ flex: 1 }}>
								<span className="p-input-icon-left p-float-label">
									<i className="ri-sticky-note-line"></i>
									<InputText id={`notes-${i}`} placeholder="Notes" />
								</span>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
								<div style={{ display: 'flex' }}>
									<div style={{ flex: 1 }}>
										<span className="p-input-icon-left p-float-label">
											<i className="ri-spotify-line"></i>
											<InputText disabled id={`spotify-id-${i}`} placeholder="Spotify ID (autofilled)" />
										</span>
									</div>
									<Button
										type="button"
										icon={<i className="ri-clipboard-line"></i>}
										style={{ marginLeft: 10, width: 45 }}
										onClick={() => {
											const adname = document.getElementById(`adname-${i}`).value
											navigator.clipboard.writeText(`https://api.littlesymphonyrecords.com/playlist/${adname}/spotify`)
											toast.current.show({ severity: 'success', summary: 'Success', detail: 'Copied ToneDen Spotify link to clipboard', life: 3000 })
										}}></Button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
		<Toast ref={toast} />
	</React.Fragment>
}

export default LinkManager
