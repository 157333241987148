import React from 'react'
import useDarkMode from 'use-dark-mode'

// Context has been created
const ThemeContext = React.createContext(false)
// Provider
const ThemeProvider = ({ children }) => {
	const themeHandler = () => {
		const themeLink = document.getElementById('app-theme')
		if (themeLink) {
			if (darkMode.value) {
				themeLink.href = '/themes/saga-blue/theme.css'
			} else {
				themeLink.href = '/themes/arya-blue/theme.css'
			}
		}
	}

	const darkMode = useDarkMode(false, { onChange: themeHandler })

	const toggleFunction = () => {
		darkMode.toggle()
	}

	return (
		<ThemeContext.Provider value={{ darkMode, toggleFunction }}>
			{children}
		</ThemeContext.Provider>
	)
}
export { ThemeContext, ThemeProvider }
