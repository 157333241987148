// Data format
/**
 * tagData = [
 * {
 * query : string,
 * rank: number,
 * count: number
 * }
 * ]
 *
 */

export function diversityScore (tagData) {
	return getZpArray(tagData).reduce((sum, val) => sum + val, 0) * 100 / tagData.length
}

export function nuclearity (tagData) {
	const XdzpArray = getXdZpArray(tagData)
	return Math.max(XdzpArray)
}

export function balancedScore (tagData) {
	const XdzpArray = getXdZpArray(tagData)
	return Math.round(20 * XdzpArray.slice(0, 5).reduce((sum, val) => sum + val))
}

export function nameScore (playlistData) {
	return Math.round(xd(playlistData?.count) * zp(playlistData?.rank) * 100)
}

function xd (count) {
	let xd = 0
	switch (true) {
		case count < 51:
			xd = 0.05 + 0.005 * count
			break
		case count <= 200:
			xd = 0.2 + 0.002 * count
			break
		case count <= 1000:
			xd = 0.5 + 0.0005 * count
			break
		default:
			xd = 0
			break
	}
	return xd
}

function zp (rank) {
	let zp = 0
	switch (true) {
		case rank === 1:
			zp = 1
			break
		case rank <= 50:
			zp = 0.9 - 0.008 * rank
			break
		case rank <= 200:
			zp = 0.634 - 0.00267 * rank
			break
		default:
			zp = 0
			break
	}
	return zp
}

function getXdArray (tagData) {
	// Xd
	const xdArray = []
	for (let i = 0; i < tagData.length; i++) {
		xdArray.push(xd(tagData[i]?.count))
	}
	return xdArray
}

function getZpArray (tagData) {
	// Zp
	const zpArray = []
	for (let i = 0; i < tagData.length; i++) {
		zpArray.push(zp(tagData[i]?.rank))
	}
	return zpArray
}

function getXdZpArray (tagData) {
	const XdArray = getXdArray(tagData)
	const zpArray = getZpArray(tagData)
	return XdArray.map((Xd, i) => Xd * zpArray[i]).sort(function (a, b) { return b - a })
}
