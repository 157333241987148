import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { FileUploader } from 'react-drag-drop-files'
import Papa from 'papaparse'
import { Toast } from 'primereact/toast'
import { postIt } from '../../utilities/network'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { dateString } from '../../utilities/utils'
import { useAuth } from '../../hooks/useAuth'

const fileTypes = ['CSV']
const today = new Date()
const yesterday = new Date(today)
yesterday.setDate(yesterday.getDate() - 1)

const DataUploadPage = (props) => {
	const toast = useRef(null)
	const [date, setDate] = useState(yesterday)
	const [timePeriod, setTimePeriod] = useState('7')
	const [file, setFile] = useState(null)

	const { user } = useAuth()

	const SFAOptions = [
		{ label: '1 Day', value: '1' },
		{ label: '7 Day', value: '7' }
	]

	const handleFileChange = (fileNew) => {
		setFile(fileNew)
	}

	const uploadSFA = () => {
		toast.current.show({ severity: 'info', summary: 'Parsing, loading to db...', detail: 'Hmmmm...', life: 3000 })
		const reader = new FileReader()
		reader.addEventListener('load', async (event) => {
			console.log(Papa.parse(event.target.result))
			console.log(date, timePeriod)
			const res = await postIt('/sfaInfo', {
				data: JSON.stringify(Papa.parse(event.target.result)),
				date: dateString(date),
				period: timePeriod,
				userID: user.spotify_id
			})
			console.log(res)
			toast.current.show({ severity: 'success', summary: 'Done, data logged to db.', detail: 'Thanks for uploading!', life: 3000 })
		})
		reader.readAsText(file)
	}

	return <React.Fragment>
		<div className="p-card" style={{ marginTop: 10, padding: 20, borderRadius: 10 }}>
			<h3>SFA Upload</h3>
			<div style={{ marginBottom: 5 }}>
				<FileUploader handleChange={handleFileChange} name="file" types={fileTypes}
					label='Drop CSV file here' classes="sfa-drop" />
			</div>
			<div style={{ marginBottom: 5 }}>
				<Calendar showIcon showButtonBar value={date} onChange={(e) => setDate(e.value)} placeholder="Date" style={{ width: 350 }}></Calendar>
			</div>
			<div style={{ marginBottom: 5, height: 40 }}>
				<Dropdown style={{ width: 350 }} value={timePeriod} options={SFAOptions} onChange={(e) => setTimePeriod(e.value)} placeholder="Time Period" />
			</div>
			<Button
				type="button"
				label={'Upload'}
				onClick={() => uploadSFA()}></Button>
		</div>
		<Toast ref={toast} />
	</React.Fragment>
}

export default DataUploadPage
