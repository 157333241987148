import { atom } from 'recoil'

export const playlistCacheState = atom({
	key: 'playlistCacheState',
	default: []
})

export const adCampaignCacheState = atom({
	key: 'adCampaignCacheState',
	default: []
})

export const primaryPlaylistsState = atom({
	key: 'primaryPlaylistsState',
	default: []
})

export const editingPlaylistDataState = atom({
	key: 'editingPlaylistDataState',
	default: {}
})
