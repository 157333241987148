import React, { useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import Tippy from '@tippyjs/react'
import { ChildrenWithRef } from '../../utilities/ChildrenWithRef'

import './style.scss'
import { editingPlaylistDataState } from '../../utilities/store'
import { putIt } from '../../utilities/network'
import { useAuth } from '../../hooks/useAuth'

import { BsCalendarEvent, BsDice6, BsShuffle } from 'react-icons/bs'

const PlaylistCard = ({ playlist, primaries, syncChildPlaylists, manualSyncPlaylist, cardSize }) => {
	const [numSongsToRandomize, setNumSongsToRandomize] = useState(playlist?.randomize?.number_of_first_tracks || 4)
	const [primary, setPrimary] = useState(playlist?.primary || false)
	const [randomizeFirstTracks, setRandomizeFirstTracks] = useState(playlist?.randomize?.randomize_first_tracks || false)
	const [randomizeRemainingTracks, setRandomizeRemainingTracks] = useState(playlist?.randomize?.randomize_remaining_tracks || false)
	const setEditingPlaylist = useSetRecoilState(editingPlaylistDataState)

	const { user } = useAuth()

	const cardWidth = 150

	const lastUpdatedDate = new Date(playlist.last_updated)

	const switchPrimary = async val => {
		setPrimary(val)
		const res = await putIt('/playlist', { id: playlist.id, field: 'primary', value: val })
		console.log(res)
	}

	const switchRandomizeFirst = async val => {
		setRandomizeFirstTracks(val)
		const res = await putIt('/playlist', { id: playlist.id, field: 'randomize.randomize_first_tracks', value: val })
		console.log(res)
	}

	const switchRandomizeRest = async val => {
		setRandomizeRemainingTracks(val)
		const res = await putIt('/playlist', { id: playlist.id, field: 'randomize.randomize_remaining_tracks', value: val })
		console.log(res)
	}

	const inputNumSongsToRandomize = async val => {
		setNumSongsToRandomize(val)
		const res = await putIt('/playlist', { id: playlist.id, field: 'randomize.number_of_first_tracks', value: val })
		console.log(res)
	}

	return (
		<div className={`playlist-card-container ${playlist.primary ? 'playlist-card-parent' : ''}`} style={{ marginRight: '0.5%', marginBottom: 0, width: '100%' }}>
			<Card className={`shadow-2 playlist-card ${playlist.owner_id !== user.spotify_id ? 'tp-card-background' : ''}`}>
				<div style={{ display: 'flex', gap: 16 }}>
					<div style={{ flex: 1, maxWidth: cardWidth / 2 - 12 }}>
						<Link to={`/app/edit-playlist?id=${playlist.id}`} onClick={() => {
							setEditingPlaylist(playlist)
							localStorage.setItem('editingPlaylistData', JSON.stringify(playlist))
						}}>
							<div style={{ width: '100%', minWidth: cardWidth / 2 - 12, height: 0, paddingBottom: '100%', overflow: 'hidden', position: 'relative' }}>
								<img style={{ position: 'absolute', objectFit: 'cover', objectPosition: 'center center', width: '100%', height: '100%' }} src={playlist?.imgURL ? playlist.imgURL : 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/f5a34e108782021.5fc5820ec88bf.png'} />
							</div>
						</Link>
					</div>
					<div>
						<Tippy content={playlist.name}><h4 className='playlist-card-name' style={{ width: '100%' }}>{playlist.name}</h4></Tippy>
						<p>{playlist?.children ? `${playlist.children.length} child${playlist.children.length !== 1 ? 'ren' : ''}` : '0 children'}</p>
					</div>
					<div>
						<div style={{ display: 'flex', marginTop: 5 }}>
							<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<Button label="Sync" icon='pi pi-angle-double-down' onClick={() => syncChildPlaylists(playlist.id)} disabled={!(playlist?.children && playlist?.children.length > 0)}></Button>
							</div>
						</div>
						{playlist?.sync_error &&
							<div style={{ display: 'flex', marginTop: 5 }}>
								<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<Button className="p-button-danger" label="Manual Sync Required" onClick={() => manualSyncPlaylist(playlist.id)}></Button>
								</div>
							</div>
						}
					</div>
				</div>
				<div style={{ flex: 1, display: 'flex', gap: 32, alignItems: 'center', marginTop: 8 }}>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<div style={{ flex: 1 }}>
							<p>Parent</p>
						</div>
						<div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
							<InputSwitch checked={primary} onChange={(e) => switchPrimary(e.value)} disabled={playlist.owner_id !== user.spotify_id} />
						</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<Tippy content={'Playlist last updated'}><ChildrenWithRef><BsCalendarEvent /></ChildrenWithRef></Tippy>
						<p>{`${lastUpdatedDate.toLocaleString('default', { month: 'short' })} ${lastUpdatedDate.getDate()}, ${lastUpdatedDate.getFullYear()}`}</p>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<Tippy content={'Randomize first X songs'}><ChildrenWithRef><BsDice6 /></ChildrenWithRef></Tippy>
						<InputNumber style={{ width: 50, marginRight: -16 }} value={numSongsToRandomize} onValueChange={(e) => inputNumSongsToRandomize(e.value)} />
						<InputSwitch checked={randomizeFirstTracks} onChange={(e) => switchRandomizeFirst(e.value)} />
					</div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<Tippy content={'Shuffle remaining songs'}><ChildrenWithRef><BsShuffle /></ChildrenWithRef></Tippy>
						<InputSwitch checked={randomizeRemainingTracks} onChange={(e) => switchRandomizeRest(e.value)} />
					</div>

				</div>
			</Card>

		</div >
	)
}

export default PlaylistCard
