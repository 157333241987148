import React from 'react'
// import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './App.scss'
import 'tippy.js/dist/tippy.css'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import PrimeReact from 'primereact/api'

import Login from './Pages/Login'
import LoginDenied from './Pages/LoginDenied'
import LoginCallback from './Pages/LoginCallback'
import App from './Pages/App'
import ProtectedRoute from './components/ProtectedRoute'

PrimeReact.ripple = true

const useQuery = () => new URLSearchParams(useLocation().search)

const Main = () => {
	const query = useQuery()
	const id = query.get('id')

	return (
		<main>
			<Switch>
				<Route path="/login" exact component={Login} />
				<Route path="/logincallback" exact component={LoginCallback} />
				<Route path="/denied" exact component={LoginDenied} />
				<ProtectedRoute path="/app" component={App} />
				<Route path="/" exact>
					<Redirect to={{ pathname: '/app', search: id ? `?id=${id}` : '' }} />
				</Route>
				<Route
					component={() => (
						<div>
							<p>Bad page</p>
						</div>
					)}
				/>
			</Switch>
		</main>
	)
}

export default Main
