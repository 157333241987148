import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
// PrimeReact
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'
import { Column } from 'primereact/column'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
// Options

// Utilities
import { apiURL, postIt } from '../../utilities/network'
import numberWithCommas from '../../utilities/numberWithCommas'
// import dateString from '../../utilities/dateString'
// Hooks
import { useAuth } from '../../hooks/useAuth'
import useAxios from 'axios-hooks'

import './style.scss'
import { cloneDeep } from 'lodash'
import { BsPerson, BsSearch, BsXCircle } from 'react-icons/bs'

const ArenaCampaignExpansion = ({ campaignID, playlistData }) => {
	const [campaignData, setCampaignData] = useState([])
	const [tagsOptions, setTagsOptions] = useState([])
	const [allPlaylists, setAllPlaylists] = useState([])
	const [expandedRows, setExpandedRows] = useState(null)
	const toast = useRef(null)

	const { user } = useAuth()

	const [{ data, error }] = useAxios(apiURL(`/campaign?id=${campaignID}&strategy=arena`))
	const [{ data: tags, error: tagError }] = useAxios(apiURL(`/tags?org_id=${user?.role}`))

	// function addDisplayNameToPlaylistData (playlists) {
	// const newPlaylists = []
	// if (playlists) {
	// for (let i = 0; i < playlists.length; i++) {
	// const matchedData = playlistData.filter(obj => obj.id === playlists[i].id)
	// if (matchedData.length > 0) {
	// newPlaylists.push({ ...playlists[i], display_name: matchedData[0].display_name })
	// }
	// }
	// }
	// newPlaylists.push({ display_name: '' })
	// return newPlaylists
	// }

	useEffect(() => {
		console.log(data)
		if (!data || campaignData.length > 0) {
			return
		}
		setCampaignData({ arena_strategy: {}, ...data })
		async function getAllPlaylists () {
			const res = await axios.get((apiURL(`/playlists?org=${data.org_id}&tags=${data?.arena_strategy.tags.map(tag => tag.label).join(',')}`)))
			console.log(res)
			if (res.status === 200) {
				// Combine with history data
				const history = data?.arena_strategy?.history
				console.log(history)
				if (!history) {
					setAllPlaylists(res.data)
				}
				const mergedPlaylists = res.data.map(playlist => {
					const historyIndex = history.findIndex(historyItem => historyItem.id === playlist.id)
					if (historyIndex > -1) {
						playlist.history = history[historyIndex]?.history
					}
					return playlist
				})
				setAllPlaylists(mergedPlaylists)
			}
		}
		getAllPlaylists()
	}, [data])

	// Set playlist tag options
	useEffect(() => {
		if (!tags) {
			return
		}
		setTagsOptions(tags.map(tag => tag.tag))
	}, [tags])

	if (!data) {
		return <p>Loading...</p>
	}

	if (error || tagError) {
		console.log(error)
		return <p>{"Error fetching data. Please try again shortly - if it still doesn't work, talk to Brandon."}</p>
	}

	async function saveChangesToDB () {
		toast.current.show({ severity: 'info', summary: 'Saving...', detail: 'Please wait...', life: 3000 })
		// Update arena playlists
		const newArenaRes = await postIt('/campaign/updateArena', { campaignData, orgID: user?.role })
		const newCampaignData = cloneDeep(campaignData)
		newCampaignData.arena_strategy = newArenaRes.data
		console.log(newCampaignData)
		// Saves changes to database
		const savingRes = await postIt('/campaign', { orgID: user?.role, campaignID: campaignData.campaign_id, newCampaignData })
		if (savingRes.status === 200) {
			toast.current.show({ severity: 'success', summary: 'Saved changes to db', detail: 'Nice.', life: 3000 })
			setCampaignData(newCampaignData)
		} else {
			toast.current.show({ severity: 'error', summary: 'Issue saving changes to db', detail: 'Oh no.', life: 3000 })
		}
	}

	// async function getInitValueFromServer (playlistID, objectiveString) {
	// const res = await axios.get(apiURL(`/getSinglePlaylistValue?playlist=${playlistID}&objective=${objectiveString}`))
	// return res?.data?.value || ''
	// }

	const historyExpansion = (data) => {
		console.log(data)
		return <div>
			<DataTable
				value={data?.history ? data.history.reverse().slice(0, 20) : []}
				columnResizeMode="fit"
				className="p-datatable-sm"
				emptyMessage={"No history? That's odd."}>
				<Column field={'date'} header={'Date'} style={{ minWidth: 120 }} />
				<Column field="seo_start" header="seo start" style={{ maxWidth: 120 }}></Column>
				<Column field="seo_end" header="seo end" style={{ maxWidth: 120 }}></Column>
				<Column field="followers_start" header="f start" style={{ maxWidth: 120 }}></Column>
				<Column field="followers_end" header="f end" style={{ maxWidth: 120 }}></Column>
				<Column field="spend" header="spend" style={{ maxWidth: 120 }}
				></Column>
				{/* <Column field="cpf" header="CPF" style={{ maxWidth: 80 }}
					body={rowData => rowData.spend / rowData.followers_gained}
				></Column> */}
			</DataTable >
		</div>
	}

	return (
		<div style={{ display: 'flex', width: '100%' }}>
			<div style={{ flex: 1.5, width: '100%' }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)', marginLeft: 20, marginRight: 20 }}>

					{/* Header */}
					<div className="arena-header" style={{ display: 'flex', marginBottom: 20, marginTop: 10 }}>
						<div style={{ display: 'flex', marginRight: 20 }} >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<h4 style={{ margin: 0, marginRight: 10 }}>Tags</h4>
							</div>
							<MultiSelect style={{ width: 400 }} value={campaignData?.arena_strategy?.tags ? campaignData?.arena_strategy?.tags.filter(tag => tag !== '').map(tag => tag.label) : []} options={tagsOptions} onChange={(e) => {
								const newCampaignData = { ...campaignData }
								newCampaignData.arena_strategy.tags = e.value
								setCampaignData(newCampaignData)
							}} display="chip" placeholder="None selected" />
						</div>
						<div style={{ display: 'flex', marginRight: 20 }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<h4 style={{ margin: 0, marginRight: 20 }}># of Playlists</h4>
							</div>
							<InputNumber style={{ width: 100 }} value={campaignData?.arena_strategy?.num_playlists} onValueChange={(e) => {
								if (!campaignData?.arena_strategy) {
									return
								}
								const newCampaignData = { ...campaignData }
								newCampaignData.arena_strategy.num_playlists = e.value
								setCampaignData(newCampaignData)
							}} />
						</div>
						<div style={{ display: 'flex', marginRight: 20 }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<h4 style={{ margin: 0, marginRight: 20 }}># of Winners</h4>
							</div>
							<InputNumber style={{ width: 100 }} value={campaignData?.arena_strategy?.num_winners} onValueChange={(e) => {
								if (!campaignData?.arena_strategy) {
									return
								}
								const newCampaignData = { ...campaignData }
								newCampaignData.arena_strategy.num_winners = e.value
								setCampaignData(newCampaignData)
							}} />
						</div>
						<div>
							<Button icon="pi pi-save" label="Save" onClick={() => saveChangesToDB()}></Button>
						</div>
					</div>
					{/* Playlists */}
					<div style={{ display: 'flex', gap: 20 }}>
						<div style={{ flex: 1 }}>
							<DataTable
								header="The Arena"
								value={data?.arena_strategy?.current_playlists || []}
								columnResizeMode="fit"
								className="p-datatable-sm"
								emptyMessage={!data ? 'Loading...' : 'Done loading but nothing is here'}>
								<Column field={'name'} header={'Playlist'} style={{ minWidth: 300 }}
									body={rowData => <a href={`https://open.spotify.com/playlist/${rowData.id}`} target="_blank" rel="noreferrer">{rowData.name}</a>} />
								<Column field="owner_name" header="Owner" style={{ maxWidth: 280 }}></Column>
								<Column field="followers_start" header={<BsPerson size={20} />} style={{ maxWidth: 120 }}
									body={rowData => <span>{numberWithCommas(rowData.followers_start)}</span>}></Column>
								<Column field="seo_start" header={<BsSearch />} style={{ maxWidth: 120 }}
									body={rowData => <span>{numberWithCommas(rowData.seo_start)}</span>}></Column>

								<Column field="streak" header="Streak" style={{ maxWidth: 80 }}></Column>
							</DataTable >
						</div>
						<div style={{ flex: 1 }}>
							<DataTable
								header="All playlists"
								value={allPlaylists || []}
								columnResizeMode="fit"
								className="p-datatable-sm"
								expandedRows={expandedRows}
								removableSort
								resizableColumns
								sortField={'rounds'}
								sortOrder={1}
								onRowToggle={(e) => {
									setExpandedRows(e.data)
								}}
								rowExpansionTemplate={historyExpansion}
								emptyMessage={!data ? 'Loading...' : 'Done loading but nothing is here'}>
								<Column expander style={{ maxWidth: 50 }} />
								<Column field="name" header="Playlist" sortable style={{ minWidth: 300 }}
									body={rowData => <a href={`https://open.spotify.com/playlist/${rowData.id}`} target="_blank" rel="noreferrer">{rowData.name}</a>} />
								<Column field="owner_name" header="Owner" sortable style={{ maxWidth: 280 }}></Column>
								<Column field="rounds" header="Rounds" sortable style={{ maxWidth: 120 }}
									body={rowData => rowData?.history ? rowData.history.length : 0}
								></Column>
								<Column field="cpf" header="CPF" style={{ maxWidth: 80 }}
									body={rowData => {
										if (!rowData?.history) {
											return 0
										}
										const cpfs = rowData.history.map(obj => obj.spend / obj.followers_gained).filter(num => num > 0)
										return cpfs.reduce((a, b) => a + b, 0) / cpfs.length || 0
									}}
								></Column>
								<Column header="" style={{ maxWidth: 80 }} body={rowData => <BsXCircle size={24} />}></Column>
							</DataTable >
						</div>
					</div>
				</div>
			</div>
			<Toast ref={toast} />
		</div >
	)
}

export default React.memo(ArenaCampaignExpansion)
