import React, { useState, useEffect } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar'
import { BsMegaphone, BsArrowBarRight, BsArrowBarLeft, BsKey, BsLightbulb, BsLink45Deg, BsSearch, BsHouseDoor, BsTags, BsFunnel } from 'react-icons/bs'
import { RiPlayListLine, RiSearchLine, RiUserSearchLine, RiUploadCloud2Line } from 'react-icons/ri'
import { CgOrganisation, CgUser } from 'react-icons/cg'
import { TbReportSearch } from 'react-icons/tb'
// import { BiUserCircle } from 'react-icons/bi'

import { HiOutlineDotsCircleHorizontal, HiOutlineUsers } from 'react-icons/hi'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { fetcher } from '../../utilities/network'

import './style.scss'

const iconSize = 16

const Sidenav = () => {
	const [collapsed, setCollapsed] = useState(true)

	const { user } = useAuth()
	const [image, setImage] = useState('')

	useEffect(() => {
		async function getProfilePic () {
			if (user) {
				const res = await fetcher(`/profile-pic?id=${user.spotify_id}`)
				if (res.status === 200) {
					setImage(res?.data?.image_url)
				}
			}
		}

		getProfilePic()
	}, [user])

	useState(() => {
		setCollapsed(true)
	}, [])

	return (
		<ProSidebar collapsed={collapsed}>
			<SidebarHeader>
				<NavLink to="/app/user">
					<div className='sidebar-profile-container' style={{ paddingLeft: collapsed ? 14 : 20 }}>
						<div style={{ width: 50, height: 50, marginRight: 20 }}>
							<img
								alt={'Profile Picture'}
								src={
									image ||
									'https://moonvillageassociation.org/wp-content/uploads/2018/06/default-profile-picture1.jpg'
								}
								className="sidebar-profile-pic"
							/>
						</div>
						<div style={{ width: 500, overflow: 'hidden', whiteSpace: 'nowrap' }}>
							<p>
								<strong>{user.display_name || 'Loading user...'}</strong>
							</p>
							<p className='sidebar-team'>{`${user.role.toUpperCase()} Team`}</p>
						</div>
					</div>
				</NavLink>
			</SidebarHeader>
			<SidebarContent>
				<Menu iconShape="circle">
					<MenuItem icon={<BsHouseDoor size={iconSize * 1.2} />}><NavLink to={'/app/dashboard'} /></MenuItem>
					<SubMenu title="Playlists" icon={<RiPlayListLine />} >
						<MenuItem icon={<CgOrganisation size={iconSize * 1.2} />}>{user.role.toUpperCase()} Playlists<NavLink to={'/app/organization-playlists'} /></MenuItem>
						<MenuItem icon={<CgUser size={iconSize * 1.2} />}>My Playlists<NavLink to={'/app/my-playlists'} /></MenuItem>
						<MenuItem icon={<HiOutlineUsers size={iconSize} />}>{"Other's Playlists"}<NavLink to="/app/external-playlists" /></MenuItem>
					</SubMenu>
					<SubMenu title="SEO" icon={<RiSearchLine />}>
						{/* <MenuItem icon={<BsKey size={iconSize} />}>Playlist Naming<NavLink to="/app/seo/spotify-keyword-search" /></MenuItem> */}
						<MenuItem icon={<BsKey size={iconSize} />}>Spotify Keyword Search<NavLink to="/app/seo/spotify-keyword-search" /></MenuItem>
						{/* <MenuItem icon={<BsCalendar3 size={iconSize} />}>Spotify Keyword Tracking<NavLink to="/app/seo/spotify-keyword-tracking" /></MenuItem> */}
						<MenuItem icon={<BsSearch size={iconSize * 1.2} />}>Search for Playlists<NavLink to={'/app/playlistsearch'} /></MenuItem>
						<MenuItem icon={<BsTags size={iconSize * 1.2} />}>Tags<NavLink to={'/app/tags'} /></MenuItem>
					</SubMenu>
					<SubMenu title="Marketing" icon={<BsMegaphone />}>
						<MenuItem icon={<BsLink45Deg size={iconSize} />}>Link Manager<NavLink to="/app/linkmanager" /></MenuItem>
						<MenuItem icon={<BsFunnel size={iconSize} />}>Playlist Feeders<NavLink to={'/app/feeders'} /></MenuItem>
						<MenuItem icon={<BsMegaphone size={iconSize} />}>Ads Manager<NavLink to={'/app/adsmanager'} /></MenuItem>
						<MenuItem icon={<RiUserSearchLine size={iconSize} />}>Playpaster<NavLink to="/app/playpaster" /></MenuItem>
					</SubMenu>
					<MenuItem icon={< TbReportSearch size={iconSize} />}>Server Logs<NavLink to="/app/serverlogs" /></MenuItem>
					<SubMenu title="Other" icon={<HiOutlineDotsCircleHorizontal />}>
						<MenuItem icon={<RiUploadCloud2Line size={iconSize} />}>Data Upload<NavLink to="/app/dataupload" /></MenuItem>
						<MenuItem icon={<BsLightbulb size={iconSize} />}><a href="https://docs.google.com/spreadsheets/d/1GEnJOoNmxjQsmt_cnmPxFg-gpZJp6MVNhe1EFLnlTVE/edit#gid=1230901263" target="_blank" rel="noreferrer">Suggestion Box</a></MenuItem>
					</SubMenu>
				</Menu>
			</SidebarContent>
			<SidebarFooter>
				<div className='sidebar-footer' onClick={() => {
					setCollapsed(!collapsed)
				}}>
					{collapsed && <BsArrowBarRight size={24} />}
					{!collapsed && <BsArrowBarLeft size={24} />}
				</div>

			</SidebarFooter>

		</ProSidebar >
		// </div>
	)
}

export default Sidenav
