import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import useAxios from 'axios-hooks'
import { apiURL } from '../../utilities/network'

function ServerLogs () {
	const [{ data, error }] = useAxios(apiURL('/logs?count=200'))

	if (error) {
		console.log(error)
		return (<p>Error getting logs from database.</p>)
	}

	return (
		<div>
			<DataTable
				value={data || []}
				resizableColumns
				columnResizeMode="fit"
				scrollable
				scrollHeight="flex"
				className="p-datatable-sm"
				paginator
				rows={20} rowsPerPageOptions={[20, 50, 100, 200]}
				paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="{first} to {last} of {totalRecords}"
				emptyMessage={!data ? 'Loading...' : 'Done loading but nothing is here'}>
				<Column field="date" header="Date"
					style={{ maxWidth: 180 }}
					body={rowData => {
						let dateString = new Date(rowData.date).toString()
						const bracketIndex = dateString.indexOf('(')
						dateString = dateString.slice(0, bracketIndex - 1)
						const dateArray = dateString.split(' ')
						dateArray.shift()
						dateArray.pop()
						return dateArray.join(' ')
					}}></Column>
				<Column style={{ maxWidth: 80 }} field="severity" header="Severity" body={rowData => rowData.severity.toUpperCase()}></Column>
				<Column field="msg" header="Message"></Column>
				<Column field="details" header="Details"></Column>
			</DataTable >
		</div>
	)
}

export default ServerLogs
