// Langauges
export const languageOptions = [
	{ label: 'English', value: 'en' },
	{ label: 'French', value: 'fr' },
	{ label: 'Spanish', value: 'sp' },
	{ label: 'German', value: 'de' },
	{ label: 'Porteguese', value: 'pt' },
	{ label: 'Polish', value: 'pl' },
	{ label: 'Italian', value: 'it' },
	{ label: 'Tagalog', value: 'tl' },
	{ label: 'Indonesian', value: 'id' },
	{ label: 'Dutch', value: 'nl' },
	{ label: 'Swedish', value: 'sv' },
	{ label: 'Hindi', value: 'hi' },
	{ label: 'Malayalam', value: 'ml' },
	{ label: 'Mandarin', value: 'zh' },
	{ label: 'Japanese', value: 'ja' },
	{ label: 'Turkish', value: 'tr' },
	{ label: 'Finnish', value: 'fi' },
	{ label: 'Zulu', value: 'zu' },
	{ label: 'Hebrew', value: 'he' },
	{ label: 'Bengali', value: 'bn' },
	{ label: 'Ukrainian', value: 'uk' },
	{ label: 'Russian', value: 'ru' }
]

// Table Columns
export const columnList = [
	{ header: 'Fire', field: 'growth' },
	{ header: 'Flag', field: 'flagFactor' },
	{ header: 'Star', field: 'star' },
	{ header: 'Rating', field: 'rating' },
	{ header: 'Notes', field: 'notes' },
	{ header: 'Owner', field: 'owner_name' },
	{ header: 'Language', field: 'lang' },
	{ header: 'Tags', field: 'tags' },
	{ header: 'Batch', field: 'batch' },
	{ header: 'Streams - 7 Day', field: 'streams' },
	{ header: 'Listeners - 7 Day', field: 'listeners' },
	{ header: 'Followers', field: 'f_spotify' },
	{ header: 'Ad views', field: 'ad_views' },
	{ header: 'Ad Conversion %', field: 'ad_conv' },
	{ header: 'Followers - Day Change', field: 'followers_day' },
	{ header: 'Followers - Week Change', field: 'followers_week' },
	{ header: 'Followers - Month Change', field: 'followers_month' },
	{ header: 'SEO Name', field: 'seo_name' },
	{ header: 'SEO Index', field: 'SEO Index' },
	{ header: 'Last Updated', field: 'last_updated' }
]

export const todoOptions = [
	'Reviewed & good',
	'New artwork',
	'New description',
	'New name',
	'New track list'
]
