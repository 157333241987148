import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { ProgressBar } from 'primereact/progressbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { SelectButton } from 'primereact/selectbutton'
// import { Tooltip } from 'primereact/tooltip'
import PlaylistCard from './PlaylistCard'
import ParentPlaylistCard from './ParentPlaylistCard'

import { useAuth } from '../../hooks/useAuth'
import { apiURL, fetcher } from '../../utilities/network'
import useToast from '../../hooks/useToast'
import useLocalStorage from 'use-local-storage'
import useAxios from 'axios-hooks'

import { BiCheckCircle, BiXCircle } from 'react-icons/bi'

import './style.scss'
import HorizontalScroller from './HorizontalScroller'

const sizeSelectItems = [
	{ label: 'S', value: 0 },
	{ label: 'M', value: 1 },
	{ label: 'L', value: 2 }
]

const UserPlaylists = (props) => {
	const [parentPlaylists, setParentPlaylists] = useLocalStorage('parentPlaylists', [])
	const [tpParentPlaylists, setTpParentPlaylists] = useLocalStorage('tpParentPlaylists', [])
	const [includeOrgParents, setIncludeOrgParents] = useLocalStorage('includeOrgParents', false)
	const [childPlaylists, setChildPlaylists] = useState([])
	const [oneOffPlaylists, setOneOffPlaylists] = useState([])
	const [busySyncing, setBusySyncing] = useState(false)
	const [syncingModalOpen, setSyncingModalOpen] = useState(false)
	const [syncingMessage, setSyncingMessage] = useState('')
	const [resultsMessages, setResultsMessages] = useState([])
	const [cardSize, setCardSize] = useState(0)
	const toast = useRef(null)

	const { user, getSpotifyToken } = useAuth()

	const [{ data, error }] = useToast(useAxios(apiURL(`/userPlaylists?userID=${user.spotify_id}&includeOrgParents=${includeOrgParents}`)), {
		loading: 'Loading playlists...',
		success: 'Game on, gamer.'
	})

	// Fetch playlist tags
	const [{ data: tags }] = useAxios(apiURL(`/tags/?org_id=${user.role}`), { useCache: false })

	const syncChildPlaylists = async (id) => {
		setSyncingModalOpen(true)
		setResultsMessages([])
		const children = data.playlists.filter(val => val?.parent && val?.parent === id)
		setSyncingMessage(`Syncing ${children.length} children`)
		setBusySyncing(true)
		const token = await getSpotifyToken()
		const maxCount = 20
		let count = 0
		let offset = 0
		const results = []
		while (count < maxCount) {
			const res = await fetcher(`/playlist/${id}/sync?userID=${user.spotify_id}&token=${token}&offset=${offset}`)
			results.unshift(...res.data.results.map(result => ({ ...result, parent: id })))
			setResultsMessages(results)
			offset = res.data.nextOffset
			count++
			if (offset === -1) {
				break
			}
		}
		setSyncingMessage('All done - see results below')
		setBusySyncing(false)
	}

	const manualSyncPlaylist = async (id) => {
		setSyncingModalOpen(true)
		setResultsMessages([])
		setSyncingMessage('Syncing 1 child')
		setBusySyncing(true)
		const token = await getSpotifyToken()
		const res = await fetcher(`/syncReplicas?childID=${id}&token=${token}`)
		setSyncingMessage('All done - see results below')
		setResultsMessages(res.data.results)
		setBusySyncing(false)
	}

	const syncAllParents = async () => {
		const results = []
		setSyncingModalOpen(true)
		setResultsMessages([])
		setBusySyncing(true)
		const token = await getSpotifyToken()
		const allPlaylists = [...parentPlaylists, ...tpParentPlaylists]
		for (let i = 0; i < allPlaylists.length; i++) {
			const playlist = allPlaylists[i]
			setSyncingMessage(`Syncing ${playlist.name} (${i + 1} / ${allPlaylists.length})`)
			const maxCount = 20
			let count = 0
			let offset = 0
			while (offset !== -1 && count < maxCount) {
				try {
					const res = await fetcher(`/playlist/${playlist.id}/sync?userID=${user.spotify_id}&token=${token}&offset=${offset}`)
					results.unshift(...res.data.results.map(result => ({ ...result, parent: playlist.name })))
					setResultsMessages(results)
					offset = res.data.nextOffset
					if (offset === -1) {
						break
					}
				} catch (error) {
					results.unshift({ id: playlist.id, name: playlist.name, status: 'Error - failed to sync' })
					setResultsMessages(results)
					console.log(error)
					offset = offset + 5
					break
				}
				count++
			}
		}
		setSyncingMessage(`All done - see results below. There were ${resultsMessages.filter(val => val.status.indexOf('Error') > -1).length} errors.`)
		setBusySyncing(false)
	}

	useEffect(() => {
		if (!data) {
			return
		}
		console.log(data)
		const newOneOffPlaylists = []
		const newChildPlaylists = data.playlists.filter(val => val?.parent && val?.parent !== '' && !val?.primary).sort((a, b) => a.name.localeCompare(b.name))
		const newParentPlaylists = data.playlists.filter(val => val?.primary).sort((a, b) => a.name.localeCompare(b.name))

		for (const childPlaylist of newChildPlaylists) {
			const newChildData = { id: childPlaylist.id, name: childPlaylist.name, cover: childPlaylist.imgURL }
			const parentIndex = newParentPlaylists.findIndex(val => val.id === childPlaylist.parent)
			if (parentIndex === -1) {
				newOneOffPlaylists.push(childPlaylist)
				console.log(childPlaylist)
				continue
			}
			if (newParentPlaylists[parentIndex] && 'children' in newParentPlaylists[parentIndex]) {
				newParentPlaylists[parentIndex].children.push(newChildData)
			} else {
				newParentPlaylists[parentIndex].children = [newChildData]
			}
		}
		const newUserParentPlaylists = newParentPlaylists.filter(val => val.owner_id === user.spotify_id)
		const newTpParentPlaylists = newParentPlaylists.filter(val => val.owner_id !== user.spotify_id)
		setParentPlaylists(newUserParentPlaylists)
		setChildPlaylists(newChildPlaylists)
		setTpParentPlaylists(newTpParentPlaylists)
		setOneOffPlaylists(newOneOffPlaylists.concat(data.playlists.filter(val => (!val?.parent || val?.parent === '') && !val?.primary).sort((a, b) => a.name.localeCompare(b.name))))
	}, [data])

	if (error) {
		console.log('Uh captain, we have a problemo')
	}

	return <React.Fragment>
		<div className="p-fluid">
			<div className="page-header" style={{ padding: 17 }}>
				<h2 style={{ marginTop: 3 }}>{`${user.display_name}'s`} Playlist Management</h2>
				<div style={{ display: 'flex', marginLeft: 'auto', marginRight: 0, marginTop: 0 }}>
					<div style={{ marginRight: 10 }}>
						<SelectButton value={cardSize} options={sizeSelectItems} unselectable={false} onChange={(e) => setCardSize(e.value)}></SelectButton>
					</div>
					<div style={{ marginRight: 10 }}>
						<Button className={`p-button ${!includeOrgParents ? 'p-button-outlined' : ''}`} tooltip="Show parents from org" tooltipOptions={{ position: 'bottom' }} icon="pi pi-building" onClick={() => {
							setTpParentPlaylists(tpParentPlaylists.filter(val => val?.children && val.children.length > 0))
							setIncludeOrgParents(!includeOrgParents)
						}} />
					</div>
					<div style={{ marginRight: 10 }}>
						<Button tooltip="Sync all parents" tooltipOptions={{ position: 'bottom' }} icon="pi pi-angle-double-down" onClick={() => syncAllParents()} />
					</div>
				</div>
			</div>
			<div style={{ width: '100%', overflowX: 'hidden' }}>
				{parentPlaylists && parentPlaylists.concat(tpParentPlaylists).map(parent =>
					<div key={parent.id} style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
						<div style={{ width: '100%' }}>
							<ParentPlaylistCard playlist={parent} primaries={parentPlaylists.concat(tpParentPlaylists)} manualSyncPlaylist={manualSyncPlaylist} syncChildPlaylists={syncChildPlaylists} cardSize={cardSize}></ParentPlaylistCard>
						</div>
						<HorizontalScroller>
							{childPlaylists && childPlaylists.filter(playlist => playlist.parent === parent.id).map(playlist =>
								<PlaylistCard key={playlist.id} playlist={playlist} primaries={parentPlaylists.concat(tpParentPlaylists)} manualSyncPlaylist={manualSyncPlaylist} syncChildPlaylists={syncChildPlaylists} cardSize={cardSize} allTags={tags}></PlaylistCard>
							)}
						</HorizontalScroller>

					</div>
				)
				}
			</div>
			<Accordion multiple activeIndex={[0]}>
				<AccordionTab header="Unsorted">
					<div className="card-container">
						{oneOffPlaylists && oneOffPlaylists.map(playlist => <PlaylistCard key={playlist.id} playlist={playlist} primaries={parentPlaylists.concat(tpParentPlaylists)} manualSyncPlaylist={manualSyncPlaylist} syncChildPlaylists={syncChildPlaylists} cardSize={cardSize} allTags={tags}></PlaylistCard>)}
					</div>
				</AccordionTab>
			</Accordion>
		</div >
		<Toast ref={toast} />
		<Dialog header="Syncing Time" visible={syncingModalOpen} style={{ width: '90vw', maxWidth: 1200 }}
			footer={
				<div>
					<Button label="OK" icon="pi pi-check" onClick={() => setSyncingModalOpen(false)} />
					<Button label="Cancel" className="p-button-outlined p-button-danger" icon="pi pi-times" onClick={() => setSyncingModalOpen(false)} />
				</div>
			}
			onHide={() => setSyncingModalOpen(false)}>
			<p>{syncingMessage}</p>
			{busySyncing &&
				<ProgressBar mode="indeterminate" style={{ marginBottom: 10 }} />
			}
			{
				<DataTable value={resultsMessages} responsiveLayout="scroll" emptyMessage="Syncing playlists...">
					<Column field="parent" header="Parent" style={{ fontWeight: 'bold' }}
						body={rowData => <span>{resultsMessages.filter(msg => msg.parent === rowData.parent).findIndex(msg => msg.id === rowData.id) === 0 ? rowData.parent : ''}</span>}></Column>
					<Column field="name" header="Playlist"></Column>
					<Column field="status" header="Status" body={rowData => <div style={{ color: rowData.status.indexOf('Success') > -1 ? 'green' : 'red', display: 'flex' }}><div style={{ marginTop: 1, marginRight: 8 }}>{rowData.status.indexOf('Success') > -1 ? <BiCheckCircle size={20} /> : <BiXCircle size={20} />}</div><div>{rowData.status}</div></div>}></Column>
				</DataTable>
			}
			<div>
			</div>
		</Dialog>
	</React.Fragment >
}

export default UserPlaylists
