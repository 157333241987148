// /src/hooks/useAuth.tsx
import React, { useState, createContext, useContext, useRef } from 'react'
import { fetcher, postIt } from '../utilities/network'
import { useHistory } from 'react-router-dom'

// Create the context
const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
	// Using the useState hook to keep track of the value authed (if a
	// user is logged in)
	// const [user, setUser] = useState(null)
	const userLocalStorage = localStorage.getItem('user')
	const user = useRef(userLocalStorage ? JSON.parse(userLocalStorage) : null)
	const [reRender, setReRender] = useState(false)
	const history = useHistory()

	// LOGGING IN
	// Received callback from Spotify auth API
	const login = async (spotifyID, from) => {
		// First see if there's a local login
		const local = localStorage.getItem('user')
		if (local && !spotifyID) {
			console.log('Found local user')
			const localUserData = JSON.parse(local)
			spotifyID = localUserData.spotify_id
		}
		// We have an ID - go get data from DB
		if (spotifyID) {
			console.log('Getting user info with Spotify ID')
			// Get user from DB
			const res = await fetcher(`/user?id=${spotifyID}`)
			localStorage.setItem('user', JSON.stringify(res.data))
			// setUser(res.data)
			user.current = res.data
			setReRender(!reRender)
			return res.data
		} else {
			console.log('No dice - sending to Spotify')
			// No user data locally - go to login
			history.push('/login')
		}
	}

	const getUser = () => {
		return user.current
	}

	const getSpotifyToken = async () => {
		if (user.current.expires_at < Date.now()) {
			console.log('refreshing token')
			const res = await postIt('/refreshSpotifyToken', { refreshToken: user.current.refresh_token })
			const newUser = {
				...user.current,
				access_token: res.data.access_token,
				expires_at: res.data.expires_at
			}
			user.current = newUser
			localStorage.setItem('user', JSON.stringify(newUser))
			// await userIsSet(res.data.access_token)
			return res.data.access_token
		}
		console.log('using same token')
		return user.current.access_token
	}

	const logout = async () => {
		console.log('The User has logged out')
		localStorage.removeItem('user')
		// setUser(null)
		user.current = null
		history.push('/login')
	}

	return (
		// Using the provider so that ANY component in our application can
		// use the values that we are sending.
		<AuthContext.Provider value={{ user: user.current, getUser, getSpotifyToken, login, logout }}>
			{children}
		</AuthContext.Provider>
	)
}

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext)
