import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Chip } from 'primereact/chip'
import { Chips } from 'primereact/chips'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
// import { Card } from 'primereact/card'

import useToast from '../../hooks/useToast'
import useAxios from 'axios-hooks'

import { postIt, putIt, deleteIt, apiURL } from '../../utilities/network'
import { useAuth } from '../../hooks/useAuth'
import { Toast } from 'primereact/toast'

const PlaylistTags = () => {
	const [newTagText, setNewTagText] = useState('')
	const [tags, setTags] = useState([])
	const [selectedTagIndex, setSelectedTagIndex] = useState(undefined)
	const [deleteTagDialog, setDeleteTagDialog] = useState(false)
	const [editQueriesDialog, setEditQueriesDialog] = useState(false)

	const toast = useRef(null)
	const { user } = useAuth()

	const [{ data, error }] = useToast(useAxios(apiURL(`/tags?org_id=${user.role}`)), {
		loading: 'Loading user/organization info...',
		success: "Let's go."
	})

	useEffect(() => {
		console.log(data)
		if (data) {
			setTags(data)
		}
	}, [data])

	if (error) {
		return <p>Error getting org data</p>
	}

	const onCellEditComplete = async (e) => {
		const { rowData, newValue, field, originalEvent: event } = e
		if (newValue.trim().length > 0) {
			rowData[field] = newValue
			const res = await putIt(`/tags?tag_id=${rowData._id}`, { tagData: { tag: newValue } })
			console.log(res)
			toast.current.show({ severity: 'success', summary: 'Tag updated', detail: '', life: 3000 })
		} else {
			event.preventDefault()
		}
	}

	const textEditor = (options) => {
		return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
	}

	const addTag = async () => {
		const newTag = { org_id: user.org_id, tag: newTagText, queries: [] }
		toast.current.show({ severity: 'info', summary: 'Adding tag...', detail: '', life: 3000 })
		const res = await postIt('/tags', { tagData: newTag })
		console.log(res)
		if (res.status === 200) {
			const newTags = [...tags]
			newTags.push(res.data)
			setTags(newTags)
			setNewTagText('')
			toast.current.show({ severity: 'success', summary: 'Tag added', detail: '', life: 3000 })
		}
	}

	const deleteTag = async () => {
		console.log(tags[selectedTagIndex])
		const res = await deleteIt(`/tags?tag_id=${tags[selectedTagIndex]._id}`)
		console.log(res)
		const _tags = tags.filter(val => val._id !== tags[selectedTagIndex]._id)
		setTags(_tags)
		setDeleteTagDialog(false)
		setSelectedTagIndex(undefined)

		toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tag Deleted', life: 3000 })
	}

	const actionBodyTemplate = (rowData, props) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-pencil" className="p-button-rounded p-button-info" style={{ marginRight: 4 }} onClick={() => {
					setSelectedTagIndex(props.rowIndex)
					setEditQueriesDialog(true)
				}} />
				<Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
					setSelectedTagIndex(props.rowIndex)
					setDeleteTagDialog(true)
				}} />
			</React.Fragment>
		)
	}
	const deleteTagDialogFooter = () => {
		return (
			<React.Fragment>
				<Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteTagDialog(false)} />
				<Button label="Yes" icon="pi pi-check" className="p-button" onClick={deleteTag} />
			</React.Fragment>
		)
	}

	const editQueryDialogFooter = () => {
		return (
			<React.Fragment>
				<Button label="I'm good thanks." className="p-button" onClick={async () => {
					const res = await putIt(`/tags?tag_id=${tags[selectedTagIndex]._id}`, { tagData: { queries: tags[selectedTagIndex].queries } })
					console.log(res)
					toast.current.show({ severity: 'success', summary: 'Queries updated', detail: '', life: 3000 })
					setEditQueriesDialog(false)
				}} />
			</React.Fragment>
		)
	}

	return (
		<div style={{ marginTop: 20 }}>
			<div style={{ marginBottom: 20 }}>
				<InputText value={newTagText} onChange={(e) => setNewTagText(e.target.value)} style={{ marginRight: 5 }} />
				<Button onClick={addTag}>
					Add
				</Button>
			</div >
			{/* <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
				{playlistTags && playlistTags.map(tag =>
					<Card title={tag.tag} key={tag.tag} footer={cardFooter(tag)} style={{ flex: 1, minWidth: 300 }}>
						<p className="m-0">
							7 queries
						</p>
					</Card>
				)}
			</div> */}
			<DataTable value={tags} size='small' editMode="cell" className="editable-cells-table" responsiveLayout="scroll"
				style={{ maxWidth: 1200 }}>
				<Column field='tag' header='Tag (click to edit)' style={{ minWidth: 200 }} editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete} body={rowData => <strong>{rowData.tag}</strong>} />
				<Column field='queries' header='Search Terms' body={rowData => rowData?.queries && rowData.queries.map(query => <Chip key={query} label={query} style={{ marginRight: 4, marginBottom: 4 }} />)} />
				<Column body={actionBodyTemplate} exportable={false} style={{ maxWidth: 120, minWidth: 120 }}></Column>
			</DataTable>
			<Dialog visible={deleteTagDialog} style={{ width: '550px' }} header="Confirm Tag Deletion" modal footer={deleteTagDialogFooter} onHide={() => setDeleteTagDialog(false)}>
				<div className="confirmation-content">
					<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem', marginRight: 20 }} />
					<div>
						{selectedTagIndex !== undefined && <p>Are you sure you want to delete the tag <b>{tags[selectedTagIndex]?.tag}</b>?</p>}
						{selectedTagIndex !== undefined && <p>This will remove the tag from all playlists.</p>}
					</div>

				</div>
			</Dialog>
			<Dialog visible={editQueriesDialog} style={{ width: '550px' }} header={`${tags[selectedTagIndex]?.tag} queries`} modal footer={editQueryDialogFooter} onHide={() => setEditQueriesDialog(false)}>
				<div>
					<Chips value={tags[selectedTagIndex]?.queries} style={{ width: '100%' }} onChange={(e) => {
						const newTags = [...tags]
						newTags[selectedTagIndex].queries = e.value
						setTags(newTags)
					}} />
				</div>
			</Dialog>
			<Toast ref={toast} />
		</div >
	)
}

export default PlaylistTags
