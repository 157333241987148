export const songDetails = [
	{
		name: 'Juan de Fuca',
		artist: 'Evergreen',
		url:
			'https://open.spotify.com/track/4Ktqv1dZL3ef46WltQj9AK?si=iehNam7nTG26JKR-NedhDQ'
	},
	{
		name: 'Mountains in Between',
		artist: 'Evergreen',
		url:
			'https://open.spotify.com/track/35PfDNruTQdLfCYqoOoN9p?si=DIomp-57RJSaNSVsHhhtEg'
	},
	{
		name: 'Coco',
		artist: 'Little Symphony',
		url:
			'https://open.spotify.com/track/0Y4ipB4nBIZA3dWUWcoeLB?si=9f4KGWjNRpuFaXCCsmv12w'
	},
	{
		name: 'Ikigai',
		artist: 'River Camille',
		url:
			'https://open.spotify.com/track/58CHyyhA4uzNeO3FP79Pbt?si=EGh17FVmQrKwvCzdQACYPg'
	},
	{
		name: 'A Wander in the Dark To Nowhere',
		artist: 'With Dogs',
		url:
			'https://open.spotify.com/track/7mx3Hy3F4sVrEH8wISRKLv?si=FDeHBzyCSAWwI7jObNVfqQ'
	},
	{
		name: 'Phoenix',
		artist: 'With Dogs',
		url:
			'https://open.spotify.com/track/4GSuMPE13V5Dnp7NeAAA9F?si=yi-aMXj3Qx6neqfcWI5EQw'
	},
	{
		name: 'The Thaw',
		artist: 'With Dogs',
		url:
			'https://open.spotify.com/track/3q6o8KWrzUwWEZf1pDNQFX?si=EeLO6gUbS5urHcAArjILhQ'
	},
	{
		name: 'Zora',
		artist: 'With Dogs',
		url:
			'https://open.spotify.com/track/58CHyyhA4uzNeO3FP79Pbt?si=EGh17FVmQrKwvCzdQACYPg'
	}
]

export const artistFlavour = {
	'With Dogs':
		'With Dogs is an up-and-coming Canadian pianist with strong classical influences.',
	'River Camille':
		'River Camille is the piano alias of David Shoults, a Canadian musician and producer.',
	'Little Symphony':
		'Little Symphony creates ambient music with nature sounds recorded from around the world.',
	Evergreen: 'Evergreen is a four-piece band from Edmonton, Canada.'
}
