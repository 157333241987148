import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Chart } from 'primereact/chart'
import useAxios from 'axios-hooks'
import Tippy from '@tippyjs/react'

import { ChildrenWithRef } from '../../utilities/ChildrenWithRef'
import { numberWithCommas } from '../../utilities/utils'
import { apiURL } from '../../utilities/network'
import { useAuth } from '../../hooks/useAuth'

// Icons
import { BsCalendar3Event, BsCalendar3Week, BsCalendar3, BsHeart, BsCoin } from 'react-icons/bs'

const langTransform = {
	en: 'ENG',
	de: 'GER',
	pt: 'POR',
	sp: 'ESP',
	fi: 'FIN',
	fr: 'FRA',
	id: 'IDN',
	it: 'ITA',
	ja: 'JPN',
	ml: 'MAL',
	zh: 'MAN',
	nl: 'DTC',
	pl: 'POL',
	sv: 'SWE',
	tl: 'TGL',
	tr: 'TUR',
	uk: 'UKR',
	zu: 'ZLU',
	he: 'HEB',
	hi: 'HIN',
	bn: 'BEN'
}

const Dashboard = (props) => {
	// const [loading] = useState(false)
	const [langData, setLangData] = useState([])
	const [overallFollowerChartData, setOverallFollowerChartData] = useState([])

	const { user } = useAuth()

	const [{ data: googleData, error: googleError }] = useAxios(apiURL('/googletrends'))
	const [{ data: playlistData, error: playlistError }] = useAxios(apiURL(`/playlists?org=${user.role}`))
	const [{ data: dailyPlaylistData, error: dailyPlaylistError }] = useAxios(apiURL(`/playlists?org=${user.role}&history_days=14`))
	const [{ data: adSpendData, error: adSpendError }] = useAxios(apiURL(`/organization/campaignspend?org=${user.role}`))

	useEffect(() => {
		if (!playlistData) {
			return
		}
		const newLangData = []
		for (const playlist of playlistData) {
			// Check if this language exists yet
			const langMatchIndex = newLangData.findIndex(val => (val.lang === playlist?.lang) || (!playlist.lang && val.lang === 'en'))
			if (langMatchIndex === -1) {
				// No match - new data
				const newLanguageCode = playlist?.lang || 'en'
				newLangData.push({
					lang: newLanguageCode,
					followers: playlist?.data?.f_spotify?.today?.data || 0,
					day: (playlist?.data?.f_spotify?.today?.data - playlist?.data?.f_spotify?.yesterday?.data) || 0,
					week: (playlist?.data?.f_spotify?.today?.data - playlist?.data?.f_spotify?.lastWeek?.data) || 0,
					month: (playlist?.data?.f_spotify?.today?.data - playlist?.data?.f_spotify?.lastMonth?.data) || 0
				})
			} else {
				// Matched - add data
				const oldLangObject = newLangData[langMatchIndex]
				const newLangObject = {
					...oldLangObject,
					followers: oldLangObject.followers + playlist?.data?.f_spotify?.today?.data || 0,
					day: oldLangObject.day + (playlist?.data?.f_spotify?.today?.data - playlist?.data?.f_spotify?.yesterday?.data) || 0,
					week: oldLangObject.week + (playlist?.data?.f_spotify?.today?.data - playlist?.data?.f_spotify?.lastWeek?.data) || 0,
					month: oldLangObject.month + (playlist?.data?.f_spotify?.today?.data - playlist?.data?.f_spotify?.lastMonth?.data) || 0
				}
				newLangData[langMatchIndex] = newLangObject
			}
		}
		setLangData(newLangData)
	}, [playlistData])

	useEffect(() => {
		if (!dailyPlaylistData) {
			return
		}
		const newOverallFollowerChartData = []
		const todayDate = new Date()
		for (const playlist of dailyPlaylistData) {
			if (!playlist?.data?.f_spotify) {
				continue
			}
			for (const data of playlist.data.f_spotify) {
				const dataDate = new Date(data.date)
				if (Math.floor((todayDate - dataDate) / 86400000) > 14) {
					continue
				}
				if (!data?.data || data.data === -1) {
					continue
				}
				const matchIndex = newOverallFollowerChartData.findIndex(val => val?.x === data.date)
				if (matchIndex > -1) {
					newOverallFollowerChartData[matchIndex].y = newOverallFollowerChartData[matchIndex].y + data.data
				} else {
					newOverallFollowerChartData.push({ x: data.date, y: data.data })
				}
			}
		}
		const chartData = {
			datasets: [
				{
					label: 'Total follower growth',
					data: newOverallFollowerChartData.sort(function (a, b) {
						return new Date(b.x) - new Date(a.x)
					}),
					borderColor: 'green',
					cubicInterpolationMode: 'monotone'
				}
			]
		}
		setOverallFollowerChartData(chartData)
	}, [dailyPlaylistData])

	useEffect(() => {
		console.log(adSpendData)
	}, [adSpendData])

	function colorData (value) {
		if (value > 0) {
			return '#4AAA4E'
		} else if (value < 0) {
			return 'red'
		}
		return 'inherit'
	}

	const chartOptions = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						return numberWithCommas(context.parsed.y)
					}
				}
			}
		},
		scales: {
			y: {
				stepSize: 1,
				grace: 20
			},
			x: {
				type: 'time',
				time: {
					unit: 'day'
				}
			}
		},
		animation: true,
		spanGaps: true
	}

	return <React.Fragment>
		<div style={{ display: 'flex', marginTop: 5 }}>
			<div className="p-card dash-card">
				<h3>{'Welcome back!'}</h3>
			</div>
			<div className="p-card dash-card trend-card" style={{ padding: 0, overflowY: 'hidden' }}>
				{googleError
					? <p>Error getting Google search data</p>
					: <DataTable value={googleData || []}
						scrollable scrollHeight="flex"
						emptyMessage=""
						style={{ width: '100%' }}
					>
						<Column field="query" header={<div>Google Playlist Search Trends (30 days)</div>}></Column>
						<Column field="value"
							body={(rowData) => `+${numberWithCommas(rowData.value)}%`}
						></Column>
					</DataTable>

				}
			</div>
			<div className="p-card dash-card" >
				<div>
					<p>Welcome snack</p>
				</div>
			</div>
		</div >
		<div style={{ display: 'flex', marginTop: 5 }}>
			<div className="p-card dash-card">
				<h3>{'Welcome back!'}</h3>
			</div>
			<div className="p-card dash-card">
				<h3 style={{ marginBottom: 15 }}>{'Follower Growth'}</h3>
				{dailyPlaylistError
					? <p>Error getting playlist follower data</p>
					: <Chart type="line" data={overallFollowerChartData} options={chartOptions} />
				}
			</div>
			<div className="p-card dash-card" style={{ padding: 0, overflowY: 'hidden' }}>
				{playlistError
					? <p>Error getting playlist follower data</p>
					: <DataTable
						value={langData}
						scrollable scrollHeight="flex"
						columnResizeMode="fit"
						sortField={'week'}
						sortOrder={-1}
						emptyMessage=""
						style={{ width: '100%' }}
					>
						<Column field="lang" header="Language" body={rowData => <span>{langTransform[rowData.lang]}</span>}></Column>
						<Column field="followers" header={<Tippy content="Followers"><ChildrenWithRef><BsHeart size={20} /></ChildrenWithRef></Tippy>}
							sortable
							body={(rowData) => numberWithCommas(rowData.followers)}
						></Column>
						<Column field="day" header={<Tippy content="Day change"><ChildrenWithRef><BsCalendar3Event size={19} /></ChildrenWithRef></Tippy>}
							sortable
							body={(rowData) => <div style={{ color: colorData(rowData.day) }}>
								{numberWithCommas(rowData.day)}
							</div>}
						></Column>
						<Column field="week" header={<Tippy content="Week change"><ChildrenWithRef><BsCalendar3Week size={19} /></ChildrenWithRef></Tippy>}
							sortable
							body={(rowData) => <div style={{ color: colorData(rowData.week) }}>
								{numberWithCommas(rowData.week)}
							</div>}
						></Column>
						<Column field="month" header={<Tippy content="Month change"><ChildrenWithRef><BsCalendar3 size={19} /></ChildrenWithRef></Tippy>}
							sortable
							body={(rowData) => <div style={{ color: colorData(rowData.month) }}>
								{numberWithCommas(rowData.month)}
							</div>}
						></Column>
						<Column field="spend" header={<Tippy content="7 Day Spend"><ChildrenWithRef><BsCoin size={19} /></ChildrenWithRef></Tippy>}
							sortable
							body={(rowData) => {
								if (adSpendError) {
									return <div>-</div>
								}
								let langSpendTotal = 0
								if (adSpendData) {
									adSpendData.forEach(campaign => {
										if (campaign?.last_7day_spend) {
											if (rowData.lang === campaign?.lang) {
												langSpendTotal = langSpendTotal + Number(campaign.last_7day_spend)
											}
										}
									})
								}
								return <div>
									{Number(langSpendTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
								</div>
							}}
						></Column>
					</DataTable>
				}
			</div>
		</div >

	</React.Fragment >
}

export default Dashboard
