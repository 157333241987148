export const timelineOptions = [
	{ label: 'Last Month', value: 'month' },
	{ label: 'Last 2 Months', value: 'twoMonths' },
	{ label: 'Last Year', value: 'year' },
	{ label: 'All Time', value: 'all' }
]

export const dataOptions = [
	{ label: 'In Ad Feeder', value: 'advertised', color: 'black' },
	{ label: 'Followers', value: 'f_spotify', color: 'blue' },
	{ label: 'Ad Clicks', value: 'ad_views', color: 'purple' },
	{ label: '7 Day Streams', value: 'streams-7day', color: 'green' },
	{ label: '7 Day Listeners', value: 'listeners-7day', color: 'red' },
	{ label: 'Spotify Search Ranking', value: 'seo_name', color: 'orange' }
]

export const eventOptions = [
	{ label: 'Ad Campaign', value: 'AD-S' },
	{ label: 'QR CODE', value: 'QR' },
	{ label: 'Reddit Post', value: 'REDDIT-POST' },
	{ label: 'Reddit Comment', value: 'REDDIT-COM' },
	{ label: 'Self-Streaming', value: 'SS-S' },
	{ label: 'Spotify Page Feature', value: 'SPF-S' },
	{ label: 'TikTok Post', value: 'TIKTOK-POST' },
	{ label: 'Twitter Post', value: 'TWIT-POST' },
	{ label: 'Twitter Comment', value: 'TWIT-COM' },
	{ label: 'Other', value: 'OTHER' }
]
