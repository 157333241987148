import React, { useEffect } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import lumberjack from '../img/Lumberjack.gif'

const useQuery = () => new URLSearchParams(useLocation().search)

// eslint-disable-next-line promise/param-names

const LoginCallback = (props) => {
	const { login, authed } = useAuth()
	const query = useQuery()

	const id = query.get('id')
	useEffect(() => {
		async function checkAuthed () {
			if (!authed) {
				const res = await login(id)
				console.log(res)
			} else {
				history.push('/login')
			}
		}
		checkAuthed()
	}, [authed])

	return (
		<div className="App" style={{ background: '#7EB78C', height: '100vh', color: 'black' }}>
			<br />
			<h3>1 more sec...</h3>
			<img src={lumberjack} />
			<h3>Just <em>logging</em> you in...</h3>
		</div>
	)
}

export default LoginCallback
