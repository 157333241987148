// Config.js

// SPOTIFY
export const clientId = '149f235f7ad941419d900b585b05d9e3'
export const scopes = ['user-read-email']
export const authEndpoint = 'https://accounts.spotify.com/authorize'

const prod = {
	redirectUri: 'https://lsr-tools.web.app/',
	cloudFunctionURL: 'https://us-central1-lsr-tools.cloudfunctions.net/api'
}

const dev = {
	redirectUri: 'https://localhost:3000/',
	cloudFunctionURL: 'http://localhost:5001/lsr-tools/us-central1/api'
}

export const urls = process.env.FUNCTIONS_EMULATOR === true || process.env.NODE_ENV === 'development' ? dev : prod
