import * as React from 'react'
import toast from 'react-hot-toast'

// import useLoadingToast from './useLoadingToast'

export default function useWithToast (axios, { ...customMessages }) {
	const [{ data, error }] = axios

	const toastStatus = React.useRef(data ? 'done' : 'idle')

	const toastMessage = {
		success: 'Retrieved data!',
		loading: 'Loading from database...',
		error: 'Error while fetching data - please refresh to try again.',
		...customMessages
	}

	React.useEffect(() => {
		// if toastStatus is done,
		// then it is not the first render or the data is already cached
		if (toastStatus.current === 'done') return

		if (error) {
			toast.error(toastMessage.error, { id: toastStatus.current })
			toastStatus.current = 'done'
		} else if (data) {
			toast.success(toastMessage.success, { id: toastStatus.current })
			toastStatus.current = 'done'
		} else {
			toastStatus.current = toast.loading(toastMessage.loading)
		}

		return () => {
			toast.dismiss(toastStatus.current)
		}
	}, [
		data,
		error,
		toastMessage.error,
		toastMessage.loading,
		toastMessage.success
	])

	return [...axios]
}
