import React from 'react'
import Tippy from '@tippyjs/react'
import { FiChevronsUp, FiChevronUp } from 'react-icons/fi'
import { numberWithCommas } from '../utilities/utils'

export const flagColumnFormat = (rowData, flagFactors) => {
	if (!(rowData.id in flagFactors)) {
		return ''
	}
	const cf = flagFactors[rowData.id]
	const flagNum = cf.length
	if (flagNum === 0) {
		return ''
	}
	if (flagNum > 0) {
		return (
			<Tippy content={<span>{cf.map(criteria => <p key={Math.random()}>{criteria}</p>)}</span>} placement="right">
				<span>{flagNum}</span>
			</Tippy>
		)
	}
	return ''
}

export const growthColumnFormat = (rowData, growthFactors, growthFactorList) => {
	if (!(rowData.id in growthFactors)) {
		return ''
	}
	const { weekAverageFollowers, followersDiff } = growthFactors[rowData.id]
	const index = growthFactorList.current.indexOf(followersDiff)
	if (weekAverageFollowers === 0) {
		return ''
	}
	if (followersDiff > 0) {
		return <span>
			{
				index > growthFactorList.current.length / 4
					? <span style={{ color: 'orange' }}><FiChevronUp /></span>
					: <span style={{ color: 'red' }}><FiChevronsUp /></span>
			}
			{`${followersDiff}`}
		</span>
	}
	return ''
}

export function dataColor (rowData, date) {
	const diff = rowData?.data?.f_spotify?.today?.data - (rowData?.data?.f_spotify[date]?.data || rowData?.data?.f_spotify?.today?.data)
	if (!diff) {
		return 'inherit'
	}
	if (diff > 0) {
		return '#4AAA4E'
	} else if (diff < 0) {
		return 'red'
	}
	return 'inherit'
}

export function followerFormatting (rowData, targetDate, percentStats) {
	const targetData = rowData?.data?.f_spotify[targetDate]?.data
	if (!targetData) {
		return '-'
	}
	if (targetData) {
		if (percentStats) {
			if (targetData > 0) {
				console.log(rowData.name, rowData.data.f_spotify.today.data, targetData)
				return Math.round((rowData.data.f_spotify.today.data - targetData) / (targetData) * 1000) / 10 + '%'
			} else {
				return 0 + '%'
			}
		} else {
			return numberWithCommas(rowData.data.f_spotify.today.data - targetData)
		}
	} else {
		return '-'
	}
}
