import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Card } from 'primereact/card'
import { Dropdown } from 'primereact/dropdown'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Toast } from 'primereact/toast'
import { TabView, TabPanel } from 'primereact/tabview'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import axios from 'axios'
import useSWR from 'swr'
import { nameFilter, previousFilter } from '../components/Playpaster/filters'
import { artistFlavour } from '../components/Playpaster/songs'
import { fetcher, postIt } from '../utilities/network'
import dateString from '../utilities/dateString'
import { numberWithCommas } from '../utilities/utils'
import { useAuth } from '../hooks/useAuth'

import { defaultSmallMessage } from '../data/defaultMessages'

import { BsHeart } from 'react-icons/bs'

const messagedOptions = [
	{ label: 'Messaged', value: 'Messaged' },
	{ label: 'Profile Found', value: 'Profile Found' },
	{ label: "Couldn't Find", value: "Couldn't Find" }
]

const responseOptions = [
	{ label: 'Positive', value: 'Positive' },
	{ label: 'Negative', value: 'Negative' },
	{ label: 'Other', value: 'Other' }
]

const blacklistedOwners = [
	'5m8bbhb37ic8rqisxf116gklw', // LS
	'peter.macfarlane', // LS Sounds
	'621jmkyy0o5xhlcsxq8pduf5s', // LS Creations
	'i5z9i48gqz993m4z0ofz8c93u', // LSR
	'bidoofslay3r', // David Shoults
	'22dun2uryhkjj54p453v5k7za', // Lauren
	'uvefgsc9awlnsgk180e7vypcw' // Signal Alchemy
]

const Playpaster = (props) => {
	const [LSRep, setLSRep] = useState(localStorage.getItem('LSRep') || '')
	const [searchTerm, setSearchTerm] = useState('')
	const [playlists, setPlaylists] = useState([])
	const offset = useRef(0)
	const [playlistsToKeep, setPlaylistsToKeep] = useState([])
	const [alertOpen, setAlertOpen] = useState('none')
	const [badNameCount, setBadNameCount] = useState(0)
	const [alreadyGotItCount, setAlreadyGotItCount] = useState(0)
	const [searchTotal, setSearchTotal] = useState(0)
	const [noPicCount, setNoPicCount] = useState(0)
	const [activeIndex, setActiveIndex] = useState(0)
	const [messageTemplate, setMessageTemplate] = useState(localStorage.getItem('messageTemplate') || defaultSmallMessage)
	const [selectedSong, setSelectedSong] = useState(localStorage.getItem('selectedSong') || '')
	const [responses, setResponses] = useState({})
	const [notes, setNotes] = useState({})
	const [messagedStatus, setMessagedStatus] = useState({})
	const [dataLoading, setDataLoading] = useState(false)

	const playlistsInDB = useRef(null)
	const ownersInDB = useRef(null)
	const toast = useRef(null)
	const debounceLastKeystroke = useRef(0)

	let tooFewFollowersList = []

	const { data, error } = useSWR('/playpasterplaylists', fetcher)
	const { getSpotifyToken } = useAuth()

	useEffect(() => {
		localStorage.setItem('selectedSong', selectedSong)
		localStorage.setItem('LSRep', LSRep)
		localStorage.setItem('messageTemplate', messageTemplate)
	}, [selectedSong, LSRep, messageTemplate])

	useEffect(() => {
		if (data) {
			playlistsInDB.current = data.data.map((val) => val.id)
			ownersInDB.current = data.data.map((val) => val.owner_id)
			const newResponses = {}
			const newNotes = {}
			for (const item of data.data) {
				newResponses[item.id] = item?.response
				newNotes[item.id] = item?.notes
			}
			setResponses(newResponses)
			setNotes(newNotes)
		}
		tooFewFollowersList = localStorage.getItem('tooFewFollowersList') || []
	}, [data])

	useEffect(() => {
		if (document.getElementById('message-small')) {
			document.getElementById('message-small').value =
				localStorage.getItem('message-small') || defaultSmallMessage
		}
	}, [])

	if (error) {
		return <p>Error getting playlists from database</p>
	}

	const _handleKeyDown = async (e) => {
		if (e.key === 'Enter') {
			await searchTime()
		}
	}

	// Take a Spotify search query, filter out results, and present what's left
	async function searchTime () {
		setDataLoading(true)
		const goodToken = await getSpotifyToken()
		const res = await fetcher(`/searchSpotifyPlaylists?query=${searchTerm}&offset=${offset.current}&token=${goodToken}`)
		console.log(res)
		let playlistsTemp = res.data.playlists

		setSearchTotal(res.data.total)

		offset.current = offset.current + playlistsTemp.length

		// Sort and filter
		const batchSize = playlistsTemp.length
		playlistsTemp = nameFilter(playlistsTemp)
		const nameFilterCount = batchSize - playlistsTemp.length

		setBadNameCount(nameFilterCount)

		playlistsTemp = previousFilter(
			playlistsTemp,
			tooFewFollowersList,
			playlistsInDB.current
		)
		const previousFilterCount =
			batchSize - nameFilterCount - playlistsTemp.length

		setAlreadyGotItCount(previousFilterCount)

		// Filter out old owners
		playlistsTemp = playlistsTemp.filter(obj => !blacklistedOwners.includes(obj.owner.id))
		const playlistsToShow = []
		let noPicCountTemp = 0
		// Get user photos
		let idRes
		try {
			const idArray = playlistsTemp.map(obj => obj.owner.id)
			idRes = await postIt(`/getSpotifyUsers?token=${goodToken}`, { ids: idArray })
		} catch (e) {
			console.log(e)
		}
		console.log(idRes)
		for (let i = 0; i < idRes.data.users.length; i++) {
			const playlist = playlistsTemp[i]
			console.log(i)
			// If they have a picture, add them to the list
			if (idRes.data.users[i].images.length > 0) {
				playlistsToShow.push({
					id: playlist.id,
					description: playlist.description,
					name: playlist.name,
					owner: playlist.owner.display_name,
					ownerId: playlist.owner.id,
					image: idRes.data.users[i].images[0].url
				})
			} else {
				noPicCountTemp++
			}
		}

		setNoPicCount(noPicCountTemp)
		setPlaylists(playlistsToShow)
		setDataLoading(false)
	}

	// When you click on a playlist result, search for followers and add it to the list to search on FB
	const addToList = async (e) => {
		const playlistID = e.target.id
		const playlistInfo = playlists.find((x) => x.id === playlistID)
		let newPlaylist = []
		document.getElementById(e.target.id).parentElement.style.opacity = 0.2
		const accessToken = await getSpotifyToken()
		try {
			const apiURL = `https://api.spotify.com/v1/playlists/${playlistID}`
			const res = await axios.get(apiURL, {
				headers: {
					Authorization: 'Bearer ' + accessToken,
					'Content-Type': 'application/json'
				}
			})

			if (res.status === 200) {
				console.log('got data', res.data.name)
				const d = res.data
				if (d.followers.total > 3) {
					newPlaylist = {
						...playlistInfo,
						playlist_url: d.external_urls.spotify,
						owner_url: d.owner.external_urls.spotify,
						followers: d.followers.total
					}
					setPlaylistsToKeep([...playlistsToKeep, newPlaylist])
				} else {
					tooFewFollowersList.push(playlistID)
				}
			} else {
				setAlertOpen('block')
			}
		} catch (e) {
			setAlertOpen('block')
		}
	}

	const copyMessage = (info) => {
		if (!selectedSong || LSRep === '') {
			alert(
				'Empty song or name. Make sure you selected both at the top!'
			)
			return
		}

		const copyText = document.getElementById('message-small').value
		// Replace all elements
		console.log(selectedSong)
		const text = copyText
			.replaceAll('{first name}', info.owner.split(' ')[0])
			.replaceAll('{name}', info.owner)
			.replaceAll('{playlist name}', info.name)
			.replaceAll('{song name}', selectedSong.name)
			.replaceAll('{song link}', selectedSong)
			.replaceAll('{artist name}', selectedSong.artist)
			.replaceAll(
				'{artist flavour text}',
				artistFlavour[selectedSong.artist]
			)
			.replaceAll('{your name}', LSRep)

		const textArea = document.createElement('textarea')
		textArea.style.position = 'fixed'
		textArea.style.top = 0
		textArea.style.left = 0
		textArea.style.width = '2em'
		textArea.style.height = '2em'
		textArea.style.padding = 0
		textArea.style.border = 'none'
		textArea.style.outline = 'none'
		textArea.style.boxShadow = 'none'
		textArea.style.background = 'transparent'
		textArea.value = text
		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()
		try {
			const successful = document.execCommand('copy')
			const msg = successful ? 'successful' : 'unsuccessful'
			console.log('Copying text command was ' + msg)
		} catch (err) {
			console.log('Oops, unable to copy')
		}

		document.body.removeChild(textArea)
		toast.current.show({
			severity: 'info',
			summary: 'Copied to Clipboard',
			detail: 'Woohoo!',
			life: 3000
		})
	}

	const syncPlaylistsToDB = async () => {
		const newPlaylistDocs = []
		const playlistArray = JSON.parse(JSON.stringify(playlistsToKeep))
		if (!LSRep) {
			toast.current.show({
				severity: 'warn',
				summary: 'Whoops',
				detail: 'Please select your name at the top of the form!',
				life: 3000
			})
		}

		if (playlistArray.length === 0) {
			toast.current.show({
				severity: 'warn',
				summary: 'Whoops',
				detail: 'Please select at least one playlist to save.',
				life: 3000
			})
		}

		let numIgnored = 0
		let numAdded = 0
		for (let i = 0; i < playlistArray.length; i++) {
			const playlist = playlistArray[i]
			// If they haven't indicated a status, skip it
			if (!(playlist.id in messagedStatus)) {
				numIgnored++
				continue
			}
			const newPlaylistDoc = {
				id: playlist.id,
				playlist_name: playlist.name,
				owner_name: playlist.owner,
				owner_id: playlist.ownerId,
				description: playlist.description,
				followers: playlist.followers,
				ls_rep: LSRep || '',
				social_url: document.getElementById(`fblink-${playlist.id}`).value,
				status: messagedStatus[playlist.id],
				date: dateString(new Date())
			}
			newPlaylistDocs.push(newPlaylistDoc)
			numAdded++
		}

		// Now send data to database
		if (newPlaylistDocs.length > 0) {
			toast.current.show({
				severity: 'info',
				summary: 'Saving to Database',
				detail: 'Here goes nothing...',
				life: 3000
			})
			const postRes = await postIt('/addplaypasterplaylists', { playlists: newPlaylistDocs })
			console.log(postRes)
			if (postRes.status === 200) {
				toast.current.show({
					severity: 'success',
					summary: 'Successful',
					detail: `${numAdded} playlists added to Playpaster database!
					${numIgnored} ignored because status not selected.`,
					life: 3000
				})
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'We had an issue adding these playlists to the DB. Try again or contact Brandon.',
					life: 5000
				})
			}
		}
	}

	const resultCards = playlists.map((playlist) => {
		const header = (
			<img
				src={playlist.image}
				alt="FB pic not loading - disable Tracking Protection in Firefox"
				style={{ width: '100%', cursor: 'pointer' }}
				id={playlist.id}
				onClick={addToList}
			/>
		)

		return (
			<Card
				key={playlist.id}
				title={playlist.owner}
				subTitle={
					playlist.image.indexOf('fbcdn') > -1
						? 'Picture: Spotify'
						: 'Picture: Facebook'
				}
				style={{ width: 220, margin: 5 }}
				header={header}>
				<div>
					<h4 style={{ marginTop: 5, marginBottom: 0 }}>
						{playlist.name}
					</h4>
					<p className="p-m-0" style={{ lineHeight: '1.5' }} dangerouslySetInnerHTML={{
						__html: playlist.description.indexOf('@') > -1 ||
							playlist.description.indexOf('.com') > -1 ||
							playlist.description.toLowerCase().indexOf('submission') ||
							playlist.description.toLowerCase().indexOf('submit')
							? playlist.description
							: ''
					}}>

					</p>
				</div>
			</Card>
		)
	})

	const playlistsToKeepElements = playlistsToKeep.map((info) => (
		<div
			key={info.id}
			style={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				margin: 10
			}}>
			<a href={info.image} target="_blank" rel="noopener noreferrer">
				<img
					src={info.image}
					width={100}
					alt="FB pic not loading - click to view"
				/>
			</a>

			<div style={{ width: '40%' }}>
				<h3 style={{ margin: 2, padding: 5 }}>{info.owner}</h3>
				<h4 style={{ margin: 2, padding: 5 }}><a target="_blank" href={info.playlist_url} rel="noreferrer">{info.name}</a></h4>
				<p style={{ margin: 2, padding: 5 }}>
					{numberWithCommas(info.followers)} followers
				</p>
			</div>
			<div style={{ display: 'flex' }}>
				<a
					href={`https://www.facebook.com/search/people/?q=${info.owner}`}
					style={{
						textDecoration: 'none'
					}}
					target="_blank"
					rel="noopener noreferrer"
					onClick={(e) => {
						if (!selectedSong || LSRep === '') {
							e.preventDefault()
							alert(
								'Empty song or name. Make sure you selected both at the top!'
							)
							return
						}
						copyMessage(info)
					}}>
					<Button
						disabled={
							ownersInDB.current.indexOf(info.ownerId) > -1
						}
						style={{ marginRight: 10 }}>
						{ownersInDB.current.indexOf(info.ownerId) > -1
							? 'Owner in database'
							: <div><i className="ri-search-2-line"></i></div>}
					</Button>
				</a>
				<div>
					<InputText
						style={{ flex: 1, height: 40, marginRight: 10 }}
						placeholder="Paste Profile Link Here"
						id={`fblink-${info.id}`}
						disabled={
							ownersInDB.current.indexOf(info.ownerId) > -1
						}
					/>
					<Dropdown value={messagedStatus[info.id]} options={messagedOptions} onChange={(e) => {
						const newMessagedStatus = { ...messagedStatus }
						newMessagedStatus[info.id] = e.value
						setMessagedStatus(newMessagedStatus)
					}} placeholder="" disabled={
						ownersInDB.current.indexOf(info.ownerId) > -1
					} />
				</div>
			</div>
		</div>
	))

	const updateMessageHistory = async (rowData, field, value) => {
		const newData = { ...rowData }
		newData[field] = value
		debounceLastKeystroke.current = Date.now()
		setTimeout(async () => {
			if (Date.now() - debounceLastKeystroke.current > 1000) {
				await postIt('/updatePlaypasterPlaylist', { newData })
				toast.current.show({
					severity: 'success',
					summary: `${field} synced to database`,
					detail: `${field} for ${rowData.playlist_name} updated.`,
					life: 3000
				})
			}
		}, 1000)
	}

	return (
		<div style={{ marginTop: 20 }}>
			<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
				<TabPanel header="Search">
					<h3>1. Basic Info</h3>
					<div className="p-field">
						<label htmlFor="your-name" className="p-d-block">
							Who dis
						</label>
						<Dropdown
							id="your-name"
							value={LSRep}
							options={['Brandon', 'David', 'Bryson', 'Dave']}
							onChange={(e) => {
								setLSRep(e.target.value)
							}}
							placeholder="Choose your name"
							style={{ width: '25%', minWidth: 200 }}
						/>
					</div>
					<div className="p-field">
						<label htmlFor="song-to-promote" className="p-d-block">
							Song/Album to Promote (full link)
						</label>
						<InputText
							id="song-link"
							placeholder="ex. https://open.spotify.com/track/5mrxUVYEyowgATPU35aZay"
							value={selectedSong}
							onChange={(e) => setSelectedSong(e.target.value)}
							className="p-d-block"
							style={{ width: '100%' }}
						/>
					</div>

					<h3 style={{ marginBottom: 5 }}>2. Message Template</h3>
					{/* <div>
				<Button
					style={{ marginBottom: 5, paddingLeft: 0 }}
					className="p-button-text"
					onClick={() => { }}>
					{'Acceptable {} terms'}
				</Button>
			</div> */}
					<div className="p-field" style={{ display: 'flex' }}>
						<div style={{ flex: 1, marginRight: 20 }}>
							<label htmlFor="message-small" className="p-d-block">
								{/* <strong>Small playlist message</strong> */}
							</label>
							<InputTextarea
								style={{ width: '100%', minHeight: 300 }}
								placeholder="Small playlist message"
								id="message-small"
								value={messageTemplate}
								onChange={(e) => {
									setMessageTemplate(e.target.value)
								}}
							/>
						</div>
						<div style={{ flex: 1 }}>
							{/* <label htmlFor="message-large" className="p-d-block">
								<strong>Large playlist message</strong>
							</label>
							<InputTextarea
								style={{ width: '100%', minHeight: 300 }}
								placeholder="Large playlist message"
								id="message-large"
								onChange={(e) => {
									localStorage.setItem(
										'message-large',
										e.target.value
									)
								}}
							/> */}
						</div>
					</div>
					<div style={{ flex: 1 }}>
						{/* <label htmlFor="message-crossover" className="">
							<strong>Minimum followers for large message: </strong>
						</label>
						<InputText
							id="message-crossover"
							defaultValue="500"
							style={{ width: 100, marginBottom: 20 }}
						/> */}
						<div >
							<Button
								style={{ marginBottom: 20 }}
								className="p-button-text"
								onClick={() => {
									copyMessage({
										name: 'Test Playlist',
										owner: 'Beans McGee'
									})
								}}>
								Test Copy Message
							</Button>
						</div>
					</div>

					<h3>3. Search for playlists on Spotify</h3>
					<div>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								placeholder="Search"
								style={{ maxWidth: 400 }}
								onChange={(e) => setSearchTerm(e.target.value)}
								onKeyDown={_handleKeyDown}
							/>
						</span>
						<p style={{ color: 'red', display: 'none' }} id="dumbRemountMessage">Having that dumb remount issue. If nothing showed up, please do your search again.</p>
						<br />
					</div>
					<div>
						<p style={{ display: offset.current > 0 ? 'block' : 'none' }}>
							Gathering 100 playlists out of {numberWithCommas(searchTotal)}... Filtered{' '}
							{badNameCount} unlikely names... Filtered{' '}
							{alreadyGotItCount} previously logged playlists... Filtered{' '}
							{noPicCount} without profile pictures... Returning{' '}
							{playlists.length}.
						</p>
					</div>
					<h3>4. Pick promising playlists</h3>
					<ProgressSpinner
						style={{ display: dataLoading ? 'block' : 'none' }}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							borderRadius: 20,
							marginTop: 20
						}}>
						{resultCards}
					</div>
					<br />
					<Button onClick={() => searchTime()}>Get 50 more</Button>
					<h3>5. Find and message them on Facebook</h3>
					<ol>{playlistsToKeepElements}</ol>
					<br />

					<h3>6. Save Results to DB</h3>
					<Button onClick={() => syncPlaylistsToDB()}>
						Save Results
					</Button>
					<div
						style={{
							position: 'fixed',
							display: alertOpen,
							width: '100%',
							height: 50,
							background: 'red',
							top: 0,
							left: 0,
							textAlign: 'center'
						}}>
						<p style={{ color: 'white' }}>
							Error with Spotify request - please get new Spotify token
							(step 1)
						</p>
					</div>
				</TabPanel>
				<TabPanel header="Message History">
					<div style={{ flex: 1 }}>
						<DataTable value={data ? data.data : []}
							emptyMessage="Loading from database..."
							resizableColumns
							columnResizeMode="fit"
							removableSort
							scrollable
							scrollHeight="flex"
							className="p-datatable-sm"
						>
							<Column field="playlist_name" header="Playlist" style={{ fontWeight: 600 }} sortable
								body={(rowData) => (<a href={`https://open.spotify.com/playlist/${rowData.id}`} target="_blank" rel="noreferrer">{rowData.playlist_name}</a>)}
							></Column>
							<Column field="followers" header={<BsHeart />} style={{ maxWidth: 80 }} sortable
								body={(rowData) => numberWithCommas(rowData.followers)}
							></Column>
							<Column field="owner_name" header="Owner" style={{ maxWidth: 200 }} sortable
								body={(rowData) => (<a href={`https://open.spotify.com/user/${rowData.owner_id}`} target="_blank" rel="noreferrer">{rowData.owner_name}</a>)}
							></Column>
							<Column field="ls_rep" header="Rep" style={{ maxWidth: 100 }} sortable
							></Column>
							<Column field="status" header="Status" style={{ maxWidth: 120 }} sortable
							></Column>
							<Column field="date" header="Date" style={{ maxWidth: 120 }} sortable
							></Column>
							<Column field="social_url" header="Social" style={{ maxWidth: 120 }} sortable
								body={(rowData) => <a href={rowData.social_url} target="_blank" rel="noreferrer">{rowData.social_url === '' ? '' : (rowData.social_url.indexOf('facebook') > -1 ? 'Facebook' : 'Instagram')}</a>}
							></Column>
							<Column field="response" header="Response" style={{ maxWidth: 130 }} sortable
								body={rowData => <Dropdown value={responses[rowData.id]} options={responseOptions} optionLabel="label" optionValue="value"
									onChange={(e) => {
										const newResponses = { ...responses }
										newResponses[rowData.id] = e.value
										setResponses(newResponses)
										updateMessageHistory(rowData, 'response', e.value)
									}
									} placeholder="None"
									style={{ position: 'relative' }}
								/>}
							></Column>
							<Column field="notes" header="Notes" style={{ maxWidth: 450 }} sortable
								body={rowData => <InputText type="text" value={notes[rowData.id]} onChange={(e) => {
									const newNotes = { ...notes }
									newNotes[rowData.id] = e.target.value
									setNotes(newNotes)
									updateMessageHistory(rowData, 'notes', e.target.value)
								}
								} />}
							></Column>
						</DataTable>
					</div>
				</TabPanel>

			</TabView>

			<Toast ref={toast} />
		</div >
	)
}

export default Playpaster
